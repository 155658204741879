/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */

// ---------------------------------------------------------
// RESPONSIVE NAVIFATION FOR MOBILE
// ---------------------------------------------------------
@media (max-width: @navbarCollapseWidth) {

  // Always show submenu for navigation
  .always-show .mega > .mega-dropdown-menu,
  .always-show .dropdown-menu {
    display: block !important;
  }

  // ------------------------------------------------------
  // Support fixed navbar when collapsed
  // -------------------------------------------------------
  .navbar-collapse-fixed-top,
  .navbar-collapse-fixed-bottom {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    // style for normal collapsed menu
    .nav-collapse.in {
      position: fixed;
      width: 100%;
      overflow-y: auto;
      top: @navbarHeight;
      bottom: 0;
      > * {
        padding-bottom: 50px;
      }
    }
  }

  .navbar-collapse-fixed-bottom {
    bottom: 0;
    top: auto;
    .nav-collapse.in {
      top: 0;
      bottom: @navbarHeight;
    }
  }

  // -------------------------------------------------------
  // NAVIGATIONS ELEMENTS
  // -------------------------------------------------------
  // BREADCRUMBS
  // -------------------------------------------------------
  .plazart-navhelper {
    margin-left: -20px;
    margin-right: -20px;
  }

  // -------------------------------------------------------
  //  JA MAIN MENU
  // -------------------------------------------------------
  .plazart-mainnav {
    background: transparent;
    border-top: none;
    height: auto;
    margin-left: -20px;
    margin-right: -20px;

    // Toggle Button
    .navbar .btn-navbar {
      margin-bottom: (@PlazartglobalMargin / 2);
    }

    .navbar .btn-navbar:hover {
      cursor: pointer;
    }

    // COLLAPSIBLE NAVBAR
    // ----------------------------------------------------

    // The nav
    .nav-collapse {
      margin-top: (@PlazartglobalMargin / 2);
      background: #fff;
      visibility: visible;
      //#gradient > .vertical(@navbarBackground, @navbarBackgroundHighlight);
      &.collapse {
        display: none !important;
      }
    }

    .nav-collapse .nav {
      margin: 0;
    }

    .nav-collapse .nav > li {
    }

    .nav-collapse .nav > li > a {
      margin-bottom: 0;
    }

    // Nav and dropdown links in navbar
    // -------------------------------
    .nav-collapse .nav > li > a,
    .nav-collapse .dropdown-menu a {
      border-bottom: 1px solid @hrBorder;
      padding: (@PlazartglobalPadding / 2) @PlazartglobalPadding;
      font-weight: bold;
      //color: @navbarLinkColor;
      text-shadow: none;
      .border-radius(0);
    }

    .nav-collapse .dropdown-menu li + li a {
      margin-bottom: 0;
    }

    // Hover state
    .nav-collapse .nav > li > a:hover {
      background-color: @dropdownLinkBackgroundHover;
      background-image: none;
      //      .reset-filter();
    }

    // dropdown links
    // --------------
    .nav-collapse .dropdown-menu a {
      border-top: 1px solid darken(@navbarBackground, 5%);
      border-bottom: 0;
      font-weight: normal;
      padding: (@PlazartglobalPadding / 2) @PlazartglobalPadding;
    }

    // Hover state
    .nav-collapse .dropdown-menu a:hover {
      background-color: @dropdownLinkBackgroundHover;
      background-image: none;
      //      .reset-filter();
    }

    // Dropdowns in the navbar
    // -----------------------
    .nav-collapse .dropdown-menu {
      margin: 0;
      padding: 0;
      background-color: @navbarBackground;
      border: none;
      .border-radius(0);
      .box-shadow(none);
    }

    // Full width when fixed
    // ---------------------
    &.navbar-collapse-fixed-top {
      margin-left: 0;
      margin-right: 0;
    }
    // End
  }

  // ----------------------------------------------------
  // MISC
  // ----------------------------------------------------
  .plazart-mainnav .navbar .nav > li > .dropdown-menu {
    // Hiding Menu and Divider in nav-collapse
    &:before, &:after, .divider {
      display: none;
    }
  }

  .plazart-mainnav .customization {
    display: none;
  }

  // End Responsive
}