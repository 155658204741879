/** 
 *------------------------------------------------------------------------------
 * @package       T3 Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/plazartfw
 * @Link:         http://plazart-framework.org
 *------------------------------------------------------------------------------
 */

// ------------------------------------------------------
// NAVIGATIONS ELEMENTS
// ------------------------------------------------------

// NAV
// ------------------------------------------------------
#plazart-mainnav {
  .plazart-megamenu.animate .mega.animating > .mega-dropdown-menu{
    display: block;
  }
  .caret {
    display: none;
  }
  &.navbar-default {
    margin: 0;
    .navbar-nav {
      > li {
        > a {
          padding: 20px;
          text-transform: uppercase;
        }
        &:first-child > a {
          padding-left: 0;
        }
      }
    }
  }
  .navbar-inner .tz-icon-cross {
    color: #FFFFFF;
    line-height: 40px;
    margin-top: -20px;
    position: absolute;
    right: 20px;
    top: 50%;
    font-size: 18px;
  }

  .nav {
    margin-bottom: @PlazartglobalMargin;
    position: relative;
  }
  .ps-static {
    position: static !important;
    > .dropdown-menu {
      margin-left: 45px;
    }
  }

  .nav > li > a {
  }

  // Hover state
  .nav > li > a:hover {

  }

  // Nav arrow/caret
  // ------------------
  .nav .dropdown-toggle .caret {
    border-top-color: @linkColor;
    border-bottom-color: @linkColor;
    margin-top: 8px;
    margin-left: 5px;
  }

  .nav .dropdown-toggle:hover .caret {
    border-top-color: @linkColorHover;
    border-bottom-color: @linkColorHover;
  }

  // Active arrow/caret
  .nav .active .dropdown-toggle .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
  }
  .nav-tabs .active .dropdown-toggle .caret {
    border-top-color: @gray;
    border-bottom-color: @gray;
  }

  // DROPDOWN
  // -------------------------------------------------------

  // Use the .menu class on any <li> element within the topbar or ul.tabs and you'll get some superfancy dropdowns
  .dropup,
  .dropdown {
    position: relative;
  }
  .dropdown-toggle {
    // The caret makes the toggle a bit too tall in IE7
    *margin-bottom: -3px;
  }
  .dropdown-toggle:active,
  .open .dropdown-toggle {
    outline: 0;
  }

  // Dropdown arrow/caret
  // --------------------
  //  .caret {
  //    display: inline-block;
  //    width: 0;
  //    height: 0;
  //    vertical-align: top;
  //    border-top: 4px solid @black;
  //    border-right: 4px solid transparent;
  //    border-left: 4px solid transparent;
  //    content: "";
  //  }
  //
  //  // Place the caret
  //  .dropdown .caret {
  //    margin-top: 8px;
  //    margin-left: 2px;
  //  }

  // The dropdown menu
  // -----------------
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: @zindexDropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: 160px;
    list-style: none;
    background-color: @dropdownBackground;
    border: none; // Fallback for IE7-8
    padding: 0;
    margin: 0; // override default ul
    .border-radius(0);
    .box-shadow(none);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;

    // Aligns the dropdown menu to right
    &.pull-right {
      right: 0;
      left: auto;
    }

    // Dividers (basically an hr) within the dropdown
//    .divider {
//      .nav-divider(@dropdownDividerTop, @dropdownDividerBottom);
//    }

    // Links within the dropdown menu
    li > a {
      clear: both;
      color: @dropdownLinkColor;
      display: block;
      font-weight: normal;
      padding: 10px 15px;
      text-shadow: none;
      white-space: nowrap;
      border: none;
      line-height: 1;

    }
    .tz-menu-mega {
      > .mega-inner {
        margin: 20px 0 20px 15px;
        padding: 0 15px 0 0;
      }
      .level1 > li > a {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 7px;
        padding: 7px 15px;
      }
      ul ul li {
        > a {
          margin: -2px 0 -4px;
        }
      }
    }
    .mega-nav.level2 > li {
      &.active,
      &:hover {
        background: @white;
        > a {
          color: @black;
        }
      }
    }
  }

  // Hover state
  // -----------
  .dropdown-menu li > a:hover,
  .dropdown-menu li > a:focus,
  .dropdown-submenu:hover > a {
    color: @dropdownLinkColorHover;
    text-decoration: none;
    background-color: @dropdownLinkBackgroundHover;
//    #gradient > .vertical(lighten(@dropdownLinkBackgroundHover, 10%), @dropdownLinkBackgroundHover);
  }

  // Active state
  // ------------
  .dropdown-menu .active > a,
  .dropdown-menu .active > a:hover {
    color: @dropdownLinkColorActive;
    text-decoration: none;
    outline: 0;
    background-color: @dropdownLinkBackgroundActive;
//    #gradient > .vertical(lighten(@dropdownLinkBackgroundActive, 10%), @dropdownLinkBackgroundActive);
  }

  // Disabled state
  // --------------
  // Gray out text and ensure the hover state remains gray
  .dropdown-menu .disabled > a,
  .dropdown-menu .disabled > a:hover {
    color: @grayLight;
  }

  // Nuke hover effects
  .dropdown-menu .disabled > a:hover {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    cursor: default;
  }

  // Open state for the dropdown
  // ---------------------------
  .open {
    // IE7's z-index only goes to the nearest positioned ancestor, which would
    // make the menu appear below buttons that appeared later on the page
    *z-index: @zindexDropdown;

    & > .dropdown-menu {
      display: block;
    }
  }
  // Open dropdown caret
  .nav li.dropdown > .dropdown-toggle .caret {
    border-top-color: @navbarLinkColor;
    border-bottom-color: @navbarLinkColor;
  }

  .nav li.dropdown > .dropdown-toggle:hover .caret {
    border-top-color: @navbarLinkColorActive;
    border-bottom-color: @navbarLinkColorActive;
  }

  .nav li.dropdown.open > .dropdown-toggle .caret,
  .nav li.dropdown.active > .dropdown-toggle .caret,
  .nav li.dropdown.open.active > .dropdown-toggle .caret {
    border-top-color: @navbarLinkColorActive;
    border-bottom-color: @navbarLinkColorActive;
  }

  // Right aligned dropdowns
  // ---------------------------
  .pull-right > .dropdown-menu {
    right: 0;
    left: auto;
  }

  // Allow for dropdowns to go bottom up (aka, dropup-menu)
  // ------------------------------------------------------
  // Just add .dropup after the standard .dropdown class and you're set, bro.
  // TODO: abstract this so that the navbar fixed styles are not placed here?
  .dropup,
  .navbar-fixed-bottom .dropdown {
    // Reverse the caret
    .caret {
      border-top: 0;
      border-bottom: 4px solid @black;
      content: "";
    }
    // Different positioning for bottom up menu
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-bottom: 1px;
    }
  }

  // Dropdown Sub menus
  // ------------------
  .dropdown-submenu {
    position: relative;
  }

  // Default dropdowns
  .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    .border-radius (@baseBorderRadius);
  }

  .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }

  .touch .dropdown-submenu:hover > .dropdown-menu {
    display: none;
  }

  .touch .dropdown-submenu.open > .dropdown-menu {
    display: block;
  }

  // Dropups
  .dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px;
    .border-radius (0);
  }

  // Caret to indicate there is a submenu
  .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: darken(@dropdownBackground, 20%);
    margin-top: 5px;
    margin-right: -10px;
  }

  .dropdown-submenu:hover > a:after {
    border-left-color: @dropdownLinkColorHover;
  }

  // Left aligned submenus
  .dropdown-submenu.pull-left {
    // Undo the float
    // Yes, this is awkward since .pull-left adds a float, but it sticks to our conventions elsewhere.
    float: none;

    // Positioning the submenu
    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
      -webkit-border-radius: 6px 0 6px 6px;
      -moz-border-radius: 6px 0 6px 6px;
      border-radius: 6px 0 6px 6px;
    }
  }

  // Tweak nav headers
  // -----------------
  // Increase padding from 15px to 20px on sides
  .dropdown .dropdown-menu .nav-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  // Typeahead
  // ---------
  .typeahead {
    margin-top: 2px; // give it some space to breathe
    .border-radius(@baseBorderRadius);
  }

  //
  // Breadcrums
  // -------------------------------------------------------
  .plazart-navhelper {
    background: lighten(@grayDarker, 5%);
    color: @gray;
  }

  .breadcrumb {
    padding: (@PlazartglobalPadding / 2) 0;
    margin: 0;
    background-color: transparent;
    .border-radius(0);
    .clearfix();
    li {
      text-shadow: none;
      display: block;
      float: left;
    }
    // Links
    a {
      color: @gray;
    }
    // Divider
    .divider {
      padding: 0 5px;
      color: @gray;
      img {
        vertical-align: middle;
      }
    }
    // Active
    .active {
      color: @grayLight;
    }
  }
}

