@media (max-width: 1200px) {
  .menu, .header {
    position: fixed;
    background: @white;
    //    z-index: 111;
    width: 100%;
  }

  .vm-product-container .chzn-container-single .chzn-drop {
    min-width: 185px !important;
  }

  .padding-0-80 {
    padding: 0 20px;
  }

  .team .team-box {
    border: none;
  }

  .TzPortfolioItemPage {
    .info > .container {
      text-align: center;
    }
  }

  .tz-set-height {
    img {
      height: 30px;
    }
  }

  .button_scroll {
    display: none;
  }

  #plazart-mainnav.navbar-default .navbar-nav > li > a {
    padding: 25px 20px;
  }

  .width-auto {
    width: auto !important;
    float: left !important;
    margin-bottom: 0 !important;
  }

  .info > .container {
    text-align: left;
  }

  .navi_article .pagenav .next > * {
    text-align: right;
  }

  .navi_article .pagenav > div span {
    max-width: 45%;
  }

  .vertical .custom_text {
    position: relative;
    bottom: 0;
  }

  .liona-top-head-right {
    float: right;

  }

  .custom_text {
    float: left;
  }

  .vertical {
    .custom_text {
      margin-top: 1px;
    }
    .custom_text .text_vertical {
      .transform(none);
      top: 5px;
      position: relative;
    }
    .top-head-right {
      margin-top: 5px;
    }
    .navbar-toggle {
      top: 35px;
    }
    div.mod-languages {
      margin-top: -3px;
    }
  }

  .tz-ver #tzlogo {
    top: -5px;
    position: relative;
  }

  .main-content {
    padding: 0;
  }

  #tz-portfolio-template-info-category > .container {
    padding-top: 30px;
  }

  .TzItemPageInner {
    .tz-container-fluid {
      padding: 0;
    }
    .padding-0-75 {
      > .container {
        padding: 0;
      }
    }
  }

  .info .tz-row > div {
    float: left;
  }

  .view-article .detail > .row, .layout-blog .detail > .row {
    margin: 0;
  }

  .TzBlog {
    background: transparent;
  }

  #yourdiv {
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        h2 {
          display: none;
        }
        .title_mod {
          padding: 20px 0 0;
          margin: 0;
          h4 {
            text-align: center;
          }
        }
        .padding-bot-60 {
          padding-bottom: 0;
        }
        .box-top.row .box-info-2 {
          padding: 15px;
        }

        .custom {
          ul li, p {
            text-align: center;
          }
        }
        .weblinks {
          padding-bottom: 20px;
        }
      }
    }
  }

  .browse-view .row .product .spacer h2 {
    font-size: 11px;
  }

  .team .team-socials ul li a {
    margin: 3px;
  }

  #com-form-login .userdata .inputbox {
    width: 100%;
  }

  #checkoutForm table.cart-summary tr th {
    padding: 10px;
    font-size: 12px;
  }

  .gobal_title {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .buttonBar-right .vm-button-correct {
    font-size: 14px;
  }

  .tabs {
    > ul {
      min-height: 39px;
      li a {
        padding: 10px;
      }
    }
  }

  .tabs .tabs-pane .tab-panel {
    padding: 20px;
  }

  .rating > .voted span {
    right: 2px;
    top: 0;
  }

  div.product {
    min-width: 100px;
  }

  .tz-footer-horizontal {
    position: relative;
    .footer_info {
      position: relative;
      .transform(none);
      top: 0;
      left: 0;
    }
  }

  .tz_supersized #tz_supersized.tz_liona_supersized .slide-des .slidecaption {
    p {
      font-size: 30px;
    }
    span {
      font-size: 15px;
    }
    p, span {
      padding-right: 0;
    }
  }

  #tz_options #filter {
    width: 90%;
    margin-left: 20px;
  }

  .filter-ver {
    .filter-icon {
      font-size: 18px;
      top: 50%;
      position: absolute;
      margin-top: -10px;
    }
    .filter-close {
      font-size: 18px;
      top: 50%;
      margin-top: -10px;
    }
  }

  #tz_options #filter a {
    font-size: 14px;
  }

  .search-ver .top-search input.inputbox {
    display: inline-block;
    top: 0;
    position: relative;
    font-size: 21px;
    padding: 20px 20px 20px 60px;
  }

  .filter-portoflio-vertical #tz_options {
    padding: 20px 0;
  }

  .menu-ver,
  .filter-ver,
  .search-ver {
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;
  }

  .vertical {
    .header {
      left: 0;
      top: 0;
      padding: 30px 15px 30px;
      position: fixed;
      height: auto;
      z-index: 99999;
      background: @white;
      width: 100%;
      .transition(all 0.3s ease 0s);
      .top-head-right.liona-top-head-right > div {
        float: right;
        margin: 0 10px;
        &:first-child {
          margin: 0;
        }
      }
      #tz-logo-area {
        padding-bottom: 0;
        text-align: left;
      }
      div.mod-languages {
        margin-top: 0;
        ul.lang-inline li {
          float: left;
          border-top: none;
          border-left: 1px solid;
          padding: 0 5px;
          margin: 0;
          width: auto;
          &:first-child {
            border-left: none;
            border-top: none;
          }
          a {
            border: none;
            margin: 0;
            padding: 0;
            width: 100%;
          }
        }
      }
    }
    .tz-footer {
      .loading_portoflio {
        width: auto;
        text-align: left;
      }
    }
  }

  .tz-ver {
    #tz-logo-area {
      padding-bottom: 0;
    }
    .header > div > div#tz-liona-top-head-right > div {
      margin-bottom: 0;
      float: right;
      padding-left: 20px;
      line-height: 20px;
    }
    div.mod-languages ul.lang-inline li {
      display: inline-block;
      border-top: none;
      border-left: 1px solid;
      padding: 0 5px;
      &:first-child {
        border-left: none;
      }
    }
    #tz-liona-breadcrumbs, #tz-logo-area {
      position: relative;
    }
    #tz-logo-area,
    #tz-liona-breadcrumbs {
      bottom: auto;
    }
    #tz-liona-breadcrumbs .last_item,
    #tzlogo {
      .transform(none);
      transform-origin: inherit;
    }
  }

  .search-ver .top-search .search-close {
    top: 40%;
  }

  .TzItemPage {
    background: transparent;
  }

  .com_virtuemart {
    &.view-category {
      .no-sidebar {
        .tz-main {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}

@media (max-width: 1200px)  and  (min-width: 992px) {

  .TzBlog .TzBlogInner {
    padding: 40px 0;
    width: 100%;
  }

  .latestnews li .tz-media {
    margin-right: 0;
  }

  .latestnews li img {
    max-height: none;
    width: 100%;
  }

  .vertical {
    .menu-aside.tz-ver {
      padding: 0;
      .header {
        > div {
          > div {
            &:first-child {
              padding-right: 0;
            }
            &:last-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .view-login {
    &.vertical {
      .menu-aside.tz-ver {
        padding: 0 15px;
      }
    }
  }

  .cart-view {
    padding: 60px 30px;
  }

  .checkout-button-top .vm-button-correct, .checkout-button-top .vm-button, .vm-continue-shopping a {
    padding: 5px 0;
    font-size: 13px;
  }

  .module {
    &.padding-45-30, &.padding-30 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .tz-vm-best-seller {
    .item {
      padding: 5px 0;
      .cell-7 {
        padding: 0;
      }
      .vm-title-product {
        font-size: 13px;
        font-weight: normal;
      }
      .vm-price-value > span {
        font-size: 13px;
      }
    }
  }

  .pos_left > *, .pos_right > * {
    width: 85%;
  }

  .vm-product-container {
    padding-left: 15px;
  }

  .sidebar {
    .vm-product-container {
      .chzn-container-single {
        min-width: 185px !important;
      }
    }
  }

  .vertical {
    .tz-footer {
      .footer_info {
        display: inline-block;
        position: relative;
      }
    }
  }

  .view-article,
  .view-category {
    .sidebar {
      .tz-main {
        padding-left: 15px;
      }
    }
  }

  .com_contact.view-contact {
    .tz-main {
      padding: 0 15px;
    }
  }

  .category_info > div .category-title, .category_info > div i {
    font-size: 30px;
  }

  .category_info > div .total {
    padding-top: 15px;
  }

  .type-status {
    top: 15px;
    left: 15px;
    .product-fields {
      .hot, .hot, .hot, {
        padding: 10px 5px;
        > div {
          font-size: 10px;
        }
      }
    }
  }

  .ca-container .category_info > div .category-title {
    font-size: 30px;
  }
}

@media (max-width: 1200px)  and  (min-width: 769px) {
  .vm-product-container .vm-product-media-container {
    padding: 90px 30px 30px 30px;
  }

  .view-productdetails {
    .tz-main {
      padding-left: 15px;
    }
    .sidebar {
      .tz-main {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .com_virtuemart {
    &.view-category {
      .sidebar {
        .tz-main {
          padding-left: 15px;
          padding-right: 0;
        }
        .tz-right {
          padding-right: 15px;
          padding-left: 0;
        }
      }
    }
  }

  .sidebar {
    .vm-product-container .vm-product-details-container {
      padding-left: 25px !important;
      padding-right: 15px !important;
      &.width_auto {
        padding: 0 !important;
      }
    }
  }

  .vm-product-container .vm-product-details-container {
    padding-left: 25px !important;
    padding-top: 30px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 1200px) and (min-width: 480px) {
  .vertical {
    .main-content {
      margin-top: 90px;
    }
  }

  .horizontal {
    .main-content {
      margin-top: 70px;
    }
  }

  .loading {
    padding: 98px 30px 30px 30px !important;
  }

  .view-article,
  .view-category {
    .sidebar {
      .tz-main {
        padding-right: 15px;
        padding-left: 0;
      }
      .tz-right {
        padding-left: 15px;
        padding-right: 0;
      }
    }
    .no-sidebar {
      .tz-main {
        padding-right: 0;
      }
    }
  }

  .TzBlog {
    background: transparent;
  }

  .vertical {
    #tz-main-body-wrapper {
      padding-top: 0 !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }

  .ca-container {
    width: auto !important;
  }

  .view-login {
    #tz-main-body-wrapper .container-fluid {
      padding: 0;
    }
  }

  .view-productdetails {

    .no-sidebar {
      .tz-right {
        padding-right: 0;
      }
    }
  }

  .view-login {
    #tz-component-area {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .view-article, .layout-blog {
    .tz-right {
      padding-left: 15px;
      padding-right: 0;
    }
  }

  .page-login {
    .form-validate {
      .control-group .controls {
        input {
          width: 60%;
        }
      }
    }
  }
}

@media (max-width: 991px) {

  .filter-portoflio {
    display: none !important;
  }

  .main-content {
    margin-top: 90px;
  }

  .top-search .search .form-inline {
    width: 100%;
  }

  #tz_supersized .slide-des .slidecaption {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .top-search {
    input.inputbox {
      width: 90%;
      margin: 0 15px;
    }
    .search-close {
      right: 30px;
      top: 30px;
    }
    .search .search-icon {
      display: none;
    }
  }

  .tz_portfolio_image_gallery {
    .thumb {
      display: none;
    }
  }

  .sidebar .tz-right {
    //    padding-right: 0;
  }

  .sidebar .browse-view .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .gobal_title {
    margin-top: 20px;
  }

  .vm-cart-custom .coupons, .vm-cart-custom .shipping-payment, .vm-cart-custom .total-prices {
    width: 100%;
  }

  .latestnews li .tz-media {
    margin-right: 0;

  }

  .latestnews li img {
    max-height: none;
  }

  .menu.menu-login {
    position: relative;
    background: @cus_color4;
  }

  .cell-7 {
    padding-left: 0;
  }

  .vm-categories-menu .menu-vm li a,
  .vm-price-value > span,
  .tz-vm-best-seller .item .vm-title-product {
    font-size: 13px;
  }

  .vm-categories-random {
    i {
      font-size: 30px;
    }
    .category-title {
      font-size: 20px;
    }
    .total, .random-category-readmore {
      font-size: 10px;
    }
    .random-category-readmore {
      padding: 5px;
    }
  }

  .padding-30 {
    padding: 30px 15px;
  }

  .padding-45-30 {
    padding: 25px 15px;
  }

  .button-toggle {
    display: none;
  }

  .tz-ver .header, .tz-ver .header > div {
    height: auto;
  }

  .sm-none {
    display: none;
  }

  .tz-ver div.mod-languages ul.lang-inline li {
    border-top: none;
    border-left: 1px solid;
    padding: 0 5px;
    &:first-child {
      border-left: none;
    }
  }

  .footer_info {
    margin-top: 3px;
  }

  .tz-icon-cross {
    font-size: 15px !important;
  }

  #tz_options {
    min-height: 50px;
    top: -10px;
    .option-set {
      margin-left: 0 !important;
    }
    .filter-icon {
      position: relative;
      top: -6px;
    }
    #filter a {
      float: left;
      font-size: 16px;
      padding: 0 10px;
    }
  }

  .menu {
    display: block;
    &.index-1 {
      z-index: -1 !important;
    }
  }

  .vertical .icon_menu_ver,
  .menu-close {
    display: none;
  }

  #plazart-mainnav.navbar-default {
    position: relative;
  }

  #tz-menu-wrapper.menu {
    background: none repeat scroll 0 0 transparent !important;
    display: inline-block;
    position: fixed;
    right: 0;
    z-index: 9999999;
    width: auto;
    > .container-fluid {
      padding: 0;
      > div {
        margin: 0;
      }
    }
  }

  .horizontal {
    .navbar-toggle {
      top: 23px;
    }
  }

  .navbar-toggle {
    border: none;
    background: @white !important;
    line-height: 1;
    margin: 0;
    padding: 0;
    right: 15px;
    top: 30px;
    > i {
      font-size: 18px;
    }
    &:hover {
      background: @white;
    }
  }

}

@media (max-width: 991px) and (min-width: 480px) {
  .revo_text_2 {
    font-size: 30px !important;
  }

  .tz_supersized #tz_supersized.tz_liona_supersized .slide-des {
    padding: 0 60px;
  }

  .ca-container {
    margin-left: 0 !important;
  }

  .ca-item .category_info, .ca-item .category-image {
    margin: 0;
  }

  .category_info > div .category-title {
    font-size: 40px;
  }

  .pos_left > *, .pos_right > * {
    width: 90%;
  }

  .liona-form .controls > input, .liona-form .controls > textarea {
    width: 99%;
  }

  .TzBlog .TzBlogInner {
    width: 100%;
    padding: 45px 0;
  }

  .tz-top-head-right {
    right: 25px;
  }

  .page-login .form-validate .control-group .controls input {
    line-height: 1.3;
    width: 80%;
  }

  #TzContent .TzInner {
    margin: 10px;
  }

  .menu.menu-login li a {
    padding: 15px;
  }

  .va-slice {
    padding: 10px 0;
  }

  .vertical {
    .liona-top-head-right {
      right: 30px;
    }
  }

  .vm-product-container .vm-product-media-container {
    padding-right: 0;
    padding-left: 0;
    .main-image {
      background: transparent;
    }
  }

  .cart-view {
    padding: 30px;
  }

  .vertical {
    section.header {
      padding-top: 30px !important;
      padding-bottom: 15px;

    }
  }

  .type-status {
    top: 30px;
    left: 30px;
  }

  .type-status .product-fields .hot, .type-status .product-fields .sales, .type-status .product-fields .new {
    padding: 10px 5px;
    > div {
      font-size: 10px;
    }
  }

  .sidebar .vm-product-container .vm-product-details-container {
    padding: 15px;
  }

  .vm-product-container .vm-product-details-container {
    padding: 0;
  }

  .slick-prev {
    right: 25px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 20px;
  }
}

@media (min-width: 769px) {

  .plazart-megamenu.animate .mega > .mega-dropdown-menu {
    opacity: 1 !important;
  }

  .weblinks .slick-slide a {
    margin: 0 15px;
  }

}

@media (max-width: 768px) {

  .generate_module {
    .team .team-socials ul li a {
      margin: 3px;
    }
  }

  .view-productdetails .sidebar .tz-main {
    padding-right: 15px;
  }

  .vm-product-container .vm-product-media-container {
    position: relative;
    padding: 0;
  }

  .vm-product-container .main-image {
    width: 100%;
    img {
      height: auto;
      width: 100%;
    }
  }

  .vm-product-container .vm-product-details-container {
    padding-left: 0 !important;
    padding-top: 30px !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 768px)and (min-width: 480px) {
  .contact-address > div {
    min-height: 100px;
  }

  .com_virtuemart {
    &.view-category {
      .sidebar {
        .tz-main {
          padding-left: 15px;
          padding-right: 0;
        }
        .tz-right {
          padding-right: 15px;
          padding-left: 0;
        }
      }
    }
  }

  .vertical .navbar-toggle {
    top: 32px !important;
  }
}

@media (max-width: 767px) and (min-width: 480px) {
  .vm-product-container .vm-product-details-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 480px) {
  #plazart-mainnav .nav-collapse.navbar-collapse .plazart-megamenu {
    padding: 0 15px !important;
  }

  .main-content {
    margin-top: 70px;
  }

  .loading {
    padding: 78px 30px 30px 30px !important;
  }

  .input {
    width: 80%;
    .input__label {
      width: 100%;
      font-size: 30px;
      .input__label-content {
        bottom: 15px;
      }
    }
  }

  .width-auto {
    width: 100% !important;
    text-align: center;
  }

  .home-slide-bg {
    padding: 0;
  }

  .white_footer {
    display: none;
  }

  .contact {
    .ds-table {
      display: block;
      .ds-table-cell {
        display: block;
      }
    }
  }

  .contact-address > div {
    width: 100%;
    min-height: 0;
  }

  .contact-links {
    ul.nav-stacked {
      li {
        width: inherit;
      }
    }
  }

  .liona-form .controls > input, .liona-form .controls > textarea {
    width: 100%;
  }

  .vertical {
    section.header {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .tz-ver .header > div > div {
    padding: 0 15px;
  }

  .pos_left, .pos_right {
    width: inherit;
    > * {
      width: inherit;
      padding: 0 15px;
    }
  }

  div#comments div#comments-list-0 #comments-list-1 {
    padding-left: 30px;
  }

  .TzArticleTitle {
    font-size: 24px;
  }

  #tz-portfolio-template-fulltext {
    padding-top: 30px !important;
  }

  #tz-portfolio-template-title {
    padding-top: 20px !important;
  }

  #tz-portfolio-template-information {
    padding: 10px 0 !important;
  }

  #tz-portfolio-template-comments {
    padding-bottom: 35px !important;
  }

  section#tz-main-body-wrapper {
    padding: 0 !important;
    overflow: hidden;
  }

  .footer-login {
    padding-top: 0;
  }

  .menu.menu-login {
    display: none;
  }

  .nav-stacked,
  .page-login .form-validate .control-group .controls .ck_remember,
  .page-login .form-validate .control-group .controls input#remember {
    float: left;
  }

  .page-login .form-validate .control-group .controls input#remember, .nav-stacked {
    left: 0;
  }

  .page-login .form-validate .control-group .controls .ck_remember {
    left: 5px;
    margin: 3px;
  }

  .menu.menu-login li a {
    padding: 5px;
    font-size: 10px;
  }

  .nav-stacked li {
    width: 33%;
    text-align: left;
  }

  .page-login .form-validate .btn-primary {
    width: 100%;
    padding: 5px;
    margin-top: 15px;
    font-size: 13px;
  }

  .view-login {
    .loading {
      padding: 0 !important;
    }
  }

  .page-login {
    .form-validate {
      padding-top: 20px;
      padding-bottom: 15px;
      .control-group .controls {
        padding: 0;
        input {
          padding: 5px 10px;
          margin: 5px 0;
          width: 100%;
        }
      }
    }
    .login-desc {
      i {
        font-size: 40px;
        margin-bottom: 15px;
        display: none;
      }
      p {
        font-size: 26px;
        &.p-small {
          font-size: 14px;
        }
      }
    }
    .nav-stacked li {
      width: 100%;
    }
    .footer-login {
      padding-bottom: 30px;
    }
  }

  .product.vm-col {
    width: 100%;
  }

  .vm-pagination .vm-page-counter {
    position: relative;
  }

  #canvas {
    position: relative;
  }

  .va-slice {
    padding: 0;
  }

  .category_info > div .category-title, .category_info > div i {
    font-size: 14px;
  }

  .category_info > div .total {
    padding-top: 10px;
  }

  .tp-caption.text1, .tp-caption.text2 {
    left: 0 !important;
    text-align: center;
    width: 100%;

  }

  .tp-leftarrow.default {
    right: 105px !important;
  }

  .tp-rightarrow.tparrows.default.custom,
  .tp-leftarrow.default {
    bottom: 20px !important;
  }

  .tp-bullets.custom {
    top: 30px !important;
    display: none !important;
  }

  .revo_button_1 {
    font-size: 14px !important;
    padding: 5px 15px !important;
  }

  .revo_text_1 {
    font-size: 14px !important;
  }

  .tp-rightarrow.custom:before, .tp-leftarrow.custom:before,
  .revo_text_2 {
    font-size: 15px !important;
  }

  .main-content {
    padding-left: 0;
    padding-right: 0;
  }

  .TzStatistic .tz-video-content {
    img {
      width: 50%;
    }
  }

  .vertical {
    .header {
      height: auto;
    }
    .tz-footer .loading_portoflio #tz_append #infscr-loading {
      text-align: left;
    }
    .tz-footer {
      padding-left: 0;
      padding-right: 0;
      padding-top: 5px;
    }
    .top-head-right {
      margin-top: 10px;
    }
    .navbar-toggle {
      top: 37px;
    }
  }

  .xs-none {
    display: none;
    &.filter-portoflio {
      display: none !important;
    }
  }

  .xs-padding-right-10 {
    padding-right: 10px;
  }

  .tz_zoomslider .tz_cus_content .cus_style1 {
    font-size: 9px;
    margin-bottom: 10px;
  }

  .tz_zoomslider .tz_cus_content .tz_custom_button {
    font-size: 10px;
    margin-top: 10px;
  }

  .tz_zoomslider .tz_cus_content .cus_style2 {
    font-size: 15px;
    margin: 10px 0;
  }

  .search-close {
    top: -3px;
  }

  #tz_options {
    display: none;
  }

  .search-ver {
    .top-search {
      .search-icon {
        float: left;
        top: 25px;
      }
      input.inputbox {
        width: 100%;
        padding-right: 20px;
        float: left;
      }
      .search-close {
        top: 27px;
        right: 0;
      }
    }
  }

  #TzContent .TzInner {
    margin: 0;
  }

  .loading_portoflio {
    display: block;
    padding-bottom: 10px;
    #tz_append > a {
      font-size: 14px;
    }
  }

  .footer_info {
    display: block;
    text-align: center;
    position: relative !important;
  }

  #tz-main-body-wrapper.main-body,
  #tz-header-wrapper.header,
  #tz-footer-wrapper.footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #tz_options {
    .option-set {
      width: 85%;
    }
  }

  .navbar-toggle {
    top: 26px !important;
    right: 0;
  }

  body {
    font-size: 13px;
  }

  div.last_item > span {
    font-size: 13px;
  }

  .additional-images img {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .text-logo {
    font-size: 20px;
  }

  .vm-product-container {
    .addtocart-bar .addtocart-button {
      display: inline-block;
    }
    .size .product-fields {
      position: relative;
      top: inherit;
    }
    .addtocart-bar {
      text-align: left;
      float: left;
    }
    .spacer-buy-area .addtocart-area {
      min-height: 100px;
    }
  }

  .tabs ul li a {
    padding: 10px;
    font-size: 12px;
  }

  .tabs .tabs-pane .tab-panel {
    font-size: 12px;
  }

  .vm-title-product-detail {
    font-size: 20px;
  }

  .button-toggle {
    display: none;
  }

  .scroll-information {
    padding: 30px 0;
  }

  .ca-container {
    margin-left: 15px !important;
  }

  .ca-container .category_info > div .category-title {
    font-size: 30px;
  }

  .ca-item .category-background,
  .ca-item .category_info, .ca-item .category-image {
    margin: 0;
  }

  .tz_supersized #thumb-tray ul#thumb-list li,
  .tz_supersized #thumb-tray ul#thumb-list img {
    width: 120px;
    height: 80px;
  }

  .tz_supersized #thumb-tray {
    height: 80px !important;
  }

  .load-item {
    display: none !important;
  }

  .tz_supersized #tz_supersized.tz_liona_supersized .slide-des .slidecaption p {
    font-size: 20px;
  }

  .tz_supersized #tz_supersized.tz_liona_supersized .slide-des .slidecaption p,
  .tz_supersized #tz_supersized.tz_liona_supersized .slide-des .slidecaption span {
    margin-right: 0 !important;
  }

  .slidecaption {
    text-align: center;
  }

  .tz_supersized #thumb-tray ul#thumb-list li:hover:after,
  .tz_supersized #thumb-tray ul#thumb-list li:hover:before {
    font-size: 30px;
    padding-top: 20px;
  }

  .TzBlog .TzBlogInner {
    padding: 30px 0;
    width: 100%;
    .info_category .TzCategoryTitle {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .info_category .hr {
      margin-top: 30px;
    }
    .TzBlogTitle {
      font-size: 20px;
    }
  }

  .padding-45 {
    padding: 25px 15px 0 15px;
  }

  .blog-article .TzItem {
    padding: 35px 0;
  }

  .latestnews li .tz-media {
    margin-right: 0;
  }

  .tz-right {
    .padding-45 {
      padding-left: 0;
      padding-right: 0;

    }
  }

  #tz-portfolio-template-info-category {
    .TzCategoryTitle {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .hr {
      margin-top: 30px;
    }
  }

  .tz-blog .TzArticleTitle {
    font-size: 20px;
    padding-top: 30px;
  }

  .media-left, .media > .pull-left {
    width: 100%;
    padding-right: 0;
    img {
      width: 100%;
    }
  }

  .media-body {
    padding-top: 20px;
  }

  div#comments .comment-content {
    padding: 0;
  }

  div#comments .comment-author .j-author {
    display: block;
  }

  div#comments .comment-author span.comment-meta {
    float: none;
    > * {
      margin: 0 5px !important;
    }
  }

  .sidebar .vm-product-container {
    .vm-product-details-container {
      padding-left: 15px;
      padding-right: 15px;
    }

  }

  .vm-product-container {
    .vm-product-details-container {
      padding-left: 15px !important;
      padding-right: 15px !important;
      .chzn-container-single {
        min-width: 160px !important;
      }
    }
  }

  .slick-slider .product-field-display {
    padding: 0 5px;
  }

  .slick-list {
    margin: 0 !important;
  }

  .cart-view {
    padding: 30px 0;
    background: transparent;
  }

  .tz-right {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .com_virtuemart {
    .sidebar {
      .tz-right {
        padding-top: 30px;
      }
    }
  }

  #yourdiv {
    .weblinks .text-custom {
      padding-left: 15px;
      padding-right: 15px;
    }
    .weblinks .slick-next {
      right: 15px;
    }
    .weblinks .slick-prev {
      right: 50px;
    }
  }

  .generate_module {
    .row {
      margin: 0;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #va-container {
    margin-top: 0;
  }

  #loading img {
    width: 150px;
  }

  #tz_append #infscr-loading {
    width: 170px;
    height: 170px;
    img {
      width: 80px;
    }
  }

  .loading_portoflio {
    margin: -55px 0 0 -80px;
  }

  #TzContent {
    margin: 0 -15px !important;
  }

  .navi_article .pagenav {
    padding: 55px 0;
    > div span {
      display: none;
    }
  }

  .error_code {
    font-size: 80px;
  }

  .report_error {
    font-size: 20px;
  }

  .font-36 {
    font-size: 18px;
  }

  .font-19 {
    font-size: 12px;
  }

  .vm-pagination {
    right: 0;
    top: 70px;
    ul li a {
      padding: 10px;
      text-align: center;
    }
  }

  .view-article,
  .view-category {
    .sidebar .tz-main {
      padding-right: 15px;
    }
  }

  .white_top {
    height: 20px;
  }

  #tz-header-wrapper.header {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .tz-top-head-right {
    right: 15px;
    margin-top: 8px;
  }

  .generate_module {
    .custom {
      padding: 0 15px;
    }
    .team .team-socials ul li a {
      margin: 3px;
    }
    .row {
      &.service {
        margin: 0 15px;
      }
    }
  }

  .team > div {
    padding-left: 0;
    padding-right: 0;
    .team-socials ul li a {
      margin: 2px;
    }
  }

  .padding-0-80 {
    padding: 0 30px;
  }

  #plazart-mainnav .nav-collapse.navbar-collapse .plazart-megamenu {
    width: 100% !important;
    left: 0 !important;
    padding: 0 15px;
  }

  .carousel_info {
    padding: 15px 30px;
    .title_article {
      font-size: 14px;
      display: block !important;
    }
    .share-post{
      padding: 0;
    }
  }
}

/*---- Canvas -----*/
@media (max-width: 991px) {
  #plazart-mainnav {
    .navbar-header {
      float: none;
      min-height: 90px;
      .navbar-toggle {
        display: block;
      }
    }
    .nav-collapse.navbar-collapse {
      height: auto !important;
      position: relative !important;
      top: 0;
      &.in {
        display: block !important;
      }
      .plazart-megamenu {
        background: @white;
        height: 100%;
        overflow: auto;
        position: fixed;
        width: 100%;
        top: 70px;
        left: 0;
        padding: 0 30px 0 30px;
      }
      .navbar-nav.level0 {
        padding: 0;
        width: 100%;
        > li {
          float: none;
          &.ps-static {
            > .dropdown-menu {
              margin: 0;
            }
          }
          &.open, &.active {
            > a {
              color: @black;
            }
          }
          > a {
            padding: 10px 0;
          }
          > .dropdown-menu {
            float: none;
            position: relative;
            width: 100% !important;
          }
        }
      }
    }
  }

  #off-canvas-nav {
    .nav-collapse {
      padding: 0;
      .plazart-megamenu {
        li.dropdown > a:hover {
          color: @black;
          background: @white;
        }
        .level0 {
          padding: 0 0 30px 0;
          float: none;
          > li {
            float: none;
            &.open > a {
              background: @white;
              color: @black;
            }
            &:first-child {
              > a {
                padding-top: 10px;
              }
            }
            > a {
              font-size: 14px;
              border: none;
              padding: 5px 0 5px 20px;
              &:hover {
                background: @white;
                color: @black;
              }
              .caret {
                display: none;
              }
            }
          }
          .dropdown-menu {
            background: @white;
            position: relative;
            float: none;
            opacity: 1;
            width: 100% !important;
            .level1 {
              > li {
                > a:hover,
                &.active > a {
                  background: @black;
                  color: @white;
                }
                a {
                  border: none !important;
                  display: block;
                  padding: 5px 0 5px 20px;
                }
              }
            }
            .mega-col-nav .mega-inner {
              border: none;
            }
          }
        }
        .mega-inner {
          padding: 0 20px;
        }
      }
    }
  }

  .off-canvas {
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .off-canvas body {
    width: 100%;
    overflow-x: hidden;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .off-canvas body > * {
    left: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform 500ms ease;
    -moz-transition: -moz-transform 500ms ease;
    -o-transition: -o-transform 500ms ease;
    transition: transform 500ms ease;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .off-canvas #plazart-mainnav .nav-collapse,
  .off-canvas #plazart-mainnav .nav-collapse {
    display: none;
  }

  .off-canvas #off-canvas-nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 99999;
    height: 100%;
    background: rgba(255, 255, 255, 0.7) !important;
  }

  .off-canvas #off-canvas-nav .plazart-mainnav {
    margin: 0;
    position: fixed !important;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    overflow: auto !important;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .off-canvas #off-canvas-nav .plazart-mainnav .nav-collapse {
    height: auto;
    background: none;
    display: block !important;
  }

  .off-canvas-enabled body > * {
    -webkit-transform: translateX(250px);
    -moz-transform: translateX(250px);
    -o-transform: translateX(250px);
    transform: translateX(250px);
  }

  .off-canvas-enabled #plazart-mainnav {
    display: block;
  }
}