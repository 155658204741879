/** 
 *------------------------------------------------------------------------------
 * @package       Plazart Framework for Joomla!
 *------------------------------------------------------------------------------
 * @copyright     Copyright (C) 2004-2013 JoomlArt.com. All Rights Reserved.
 * @license       GNU General Public License version 2 or later; see LICENSE.txt
 * @authors       JoomlArt, JoomlaBamboo, (contribute to this project at github 
 *                & Google group to become co-author)
 * @Google group: https://groups.google.com/forum/#!forum/t3fw
 * @Link:         http://t3-framework.org 
 *------------------------------------------------------------------------------
 */


// -------------------------------------------------------
//  JA MAIN MENU
// -------------------------------------------------------
.plazart-mainnav {
//  #gradient > .vertical(@navbarBackgroundHighlight, @navbarBackground);

  background: transparent;
  border-radius: 0;
  border: none;
  .box-shadow(0 0 0 #ffffff);

  .navbar {
    color: @navbarText;
    margin-bottom: 0;
    margin-top: 0;
  }

  .navbar-inner {
    min-height: @navbarHeight;
    padding:  0;
    background: transparent;
    border: 0;
//    border-left: 1px solid darken(@navbarBackground, 5%);
    .border-radius(0);
    .box-shadow(none);
//    .reset-filter();
  }



  // NAVBAR
  // -------------------------------------------------------
  .navbar .nav {
    margin: 0;
  }


  // Links
  // -----
  .navbar .nav > li > a {
    border-top: 0;
    border-right: 1px solid darken(@navbarBackground, 5%);
    padding: ((@navbarHeight - @PlazartglobalPadding) / 2) @PlazartglobalPadding;
    text-shadow: none;
  }

  // Hover
  .navbar .nav > li > a:focus,
  .navbar .nav > li > a:hover {
    background-color: @navbarLinkBackgroundHover;
//    #gradient > .vertical(lighten(@navbarLinkBackgroundHover, 10%), @navbarLinkBackgroundHover);
    text-shadow: none;
    outline: none;
  }

  // Active nav items
  .navbar .nav > .active > a,
  .navbar .nav > .active > a:hover,
  .navbar .nav > .active > a:focus {
    color: @navbarLinkColorActive;
    text-shadow: none;
//    #gradient > .vertical(lighten(@navbarLinkBackgroundActive, 10%), @navbarLinkBackgroundActive);
    .box-shadow (none);
    outline: none;
  }

  // Toggle Button
  // -------------

  // Navbar button for toggling navbar items in responsive layouts
  // These definitions need to come after '.navbar .btn'
  .navbar .btn-navbar {
    float: left;
    padding: 5px 10px;
    margin: 5px 20px 0;
//    .buttonBackground(darken(@navbarBackgroundHighlight, 5%), darken(@navbarBackground, 5%));
    .box-shadow(~"inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.075)");
    
    // The icon
    [class^="icon-"],
    [class^=" icon-"] {
      color: @navbarText;
      text-shadow: none;
    }
  }


  // DROPDOWN
  // -------------------------------------------------------

  // Dropdown arrow/caret
  // --------------------
  .caret {
  }

  // Place the caret
  .dropdown .caret {
    margin-left: 5px;
  }


  // The dropdown menu
  // -----------------
  .dropdown-menu {
    .box-shadow(0 5px 10px rgba(0,0,0,.2));

    // Links within the dropdown menu
    li > a {
      border-top: 1px solid @hrBorder;
      padding: 5px 20px;
      text-shadow: none;
    }
  }

  // No border top on the first
  .dropdown-menu li:first-child > a {
    border-top: 0;
  }

  // Hide the Top arrow of the dropdown menu.
  // See bootstrap/navbar.less or comment these line if you want to show it again.
  .navbar .nav > li > .dropdown-menu {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }


  // Hover state
  // -----------
  .dropdown-menu li > a:hover,
  .dropdown-menu li > a:focus,
  .dropdown-submenu:hover > a {
  }


  // Active state
  // ------------
  .dropdown-menu .active > a,
  .dropdown-menu .active > a:hover {
  }


  // Disabled state
  // --------------
  .dropdown-menu .disabled > a,
  .dropdown-menu .disabled > a:hover {
    color: @grayLight;
  }

  .dropdown-menu .disabled > a:hover {
    background-color: transparent;
  }


  // Open State
  // ----------
  .nav li.dropdown.open > .dropdown-toggle {
    background-color: @white;
    color: @grayDarker;
  }

  // Active Open
  .nav li.dropdown.active > .dropdown-toggle,
  .nav li.dropdown.open.active > .dropdown-toggle {
    background-color: @dropdownLinkBackgroundActive;
    color: @dropdownLinkColorActive;
  }

  // The Caret
  .nav li.dropdown > .dropdown-toggle .caret {
  }

  .nav li.dropdown.open > .dropdown-toggle .caret,
  .nav li.dropdown.active > .dropdown-toggle .caret,
  .nav li.dropdown.open.active > .dropdown-toggle .caret {
  }


  // Dropdown Sub menus
  // ------------------
  .dropdown-submenu > .dropdown-menu {
  }

  .dropdown-submenu > a:after {
  }

  .dropdown-submenu:hover > a:after {
  }
  // End 
}

// -------------------------------------------------------
//  JA MAIN MENU VERTICAL
// -------------------------------------------------------
.plazart-mainnav.vertical-nav {
  background: @grayDarker;
  position: fixed;
  width: 200px;
  margin-left: -220px;
  top: 40px;
  padding: 0 0 (@PlazartglobalPadding*3.5);
  z-index:1013;
  .navbar {
    float: none;
  }

  .navbar .nav {
    float: none;
  }

  .navbar .nav > li {
    float: none;
    padding: 0 @PlazartglobalPadding;
  }

  .navbar .nav > li > a {
    border-top: 0;
    border-right: 0;
    padding: @PlazartglobalPadding 0;
    border-bottom: 1px solid #2c2c2c;
    text-shadow: none;
    text-transform: uppercase;
    background: none;
    color: @navbarLinkColor;
    &:hover, &:focus {
      color: @navbarLinkColorHover;
    }
    .mega-caption {
      text-transform: none;
      color: #444;
    }
  }

  .navbar .nav li.dropdown > .dropdown-toggle .caret {
    border-bottom: 4px solid transparent;
    border-right: 0;
    border-left: 4px solid @navbarLinkColor;
    border-top: 4px solid transparent;
    margin-top: 5px;
    float: right;
  }

  .navbar .nav li.dropdown.open > .dropdown-toggle .caret,
  .navbar .nav li.dropdown.active > .dropdown-toggle .caret,
  .navbar .nav li.dropdown.open.active > .dropdown-toggle .caret {
    border-bottom: 4px solid transparent;
    border-right: 0;
    border-left: 4px solid @navbarLinkColorActive;
    border-top: 4px solid transparent;
  }

  .navbar .nav > .active > a,
  .navbar .nav > .active > a:hover,
  .navbar .nav > .active > a:focus {
    border-bottom-color: #2c2c2c;
    color: @navbarLinkColor;
  }

  .navbar .nav > .active:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid @grayDarker;
    position: absolute;
    right: -5px;
    top: 20px;
  }

  .dropdown-menu {
    left: 100%;
    top: 0;
  }

  .navbar .nav > li > .dropdown-menu {
    left: 200px;
  }

  .navbar .nav > li > .dropdown-menu:before {
    display: none;
  }

  .caret {

  }
}

