/* ----- Start ----- */
.tz-relative {
  position: relative;
}

.header, .main-content {
  .transition(margin-top 0.3s linear 0s);
}

.set_height {
  img {
    height: 30px;
  }
}

.white_footer {
  background: @white;
  bottom: 0;
  height: 30px;
  position: fixed;
  width: 100%;
  z-index: 999999;
}

.white_top {
  background: @white;
  top: 0;
  height: 30px;
  position: fixed;
  width: 100%;
  z-index: 999999;
}

.before-load {
  > * {
    opacity: 0;
  }
  > #loading, .header {
    opacity: 1;
    z-index: 99999999;
  }
}

.detail {
  position: relative;
}

.tz-none {
  display: none;
}

.alert.alert-notice {
  border: 1px solid @cus_color2;
  border-radius: 0;
  margin: 0 60px;
  padding: 30px;
}

.view-vmsearch,
.view-category {
  .browse-view {
    margin: 0;
  }
  .sidebar {
    .tz-main {
      padding-right: 0;
    }
    .tz-right {
      padding-left: 0;
    }
  }
}

#ascrail2000-hr {
  display: none !important;
}

.view-article,
.layout-blog {
  .detail > .row {
    margin: 0 -15px;
  }
  .tz-right {
    padding-right: 45px;
  }
}

.main-content {
  > .container-fluid {
    padding: 0;
  }
}

.fix {
  position: fixed !important;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1111;
  .thumb {
    &:hover {
      .bg-over-lay {
        height: 130px;
      }
    }
    .bg-over-lay {
      height: 100px;
    }
  }
}

.tz-right .module-title {
  padding-bottom: 30px;
  margin: 0;
  > span {
    color: @black;
    position: relative;
    &:before {
      border-top: 1px dashed @cus_color3;
      bottom: -10px;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.padding-bot-60 {
  padding-bottom: 60px;
}

.padding-t-b-70px {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-45-30 {
  padding: 45px 30px;
}

.padding-40 {
  padding: 40px 0;
}

.bg-color {
  background: #3e3e3e;
  .module-title span, li a {
    color: @white !important;
  }
  li:hover a {
    color: @cus_color1 !important;
  }
}

.padding-45 {
  padding-top: 45px;
  padding-bottom: 45px;
  .padding-45 {
    padding: 0;
  }
}

.padding-top-0 {
  padding-top: 0;
}

.filter-ver {
  display: none;
  position: relative;
  z-index: 999999;
  &.active {
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.filter-portoflio-vertical {
  #tz_options {
    position: relative;
    opacity: 1;
    z-index: 1;
    padding: 15px 0;
    background: @black;
    color: @white;
    #filter {
      a {
        color: rgba(255, 255, 255, 0.5);
        &.active, &:hover, &.selected {
          color: @white;
        }
      }
    }
  }
  .filter-close {
    right: 15px;
  }
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

.tabs {
  margin: 45px 0;
  border: 1px solid @cus_color2;
  > ul {
    min-height: 59px;
  }
  ul {
    background: rgba(188, 188, 188, 0.1);

    li {
      float: left;
      a {
        font-size: 13px;
        font-weight: bold;
        color: @black;
        padding: 20px;
        display: block;
        border-right: 1px solid @cus_color2;
      }
      &.active a {
        border-bottom: 1px solid @white;
      }
      &:hover,
      &.active {
        background: @white;
        a {
          color: #e85d33;
        }
      }
    }
  }
  .tabs-pane {
    border-top: 1px solid @cus_color2;
    margin-top: -1px;
    .tab-panel {
      padding: 35px 20px 25px;
      font-size: 13px;
    }
  }
}

.width-auto {
  width: auto;
}

p {
  margin: 0;
  padding: 0;
}

.header {
  line-height: 1;
  position: relative;
  z-index: 999999;
}

.icon_menu_ver {
  display: none;
}

.filter-portoflio {
  display: none;
}

.horizontal {
  div.mod-languages {
    ul {
      &.lang-inline {
        li {
          float: left;
          border-left: 1px solid;
          margin: 0;
          text-transform: lowercase;
          padding: 0 10px;
          line-height: 1.2em;
          a {
            font-size: 14px;
            line-height: 1.2em;
          }
          &.lang-active {
            text-transform: uppercase;
          }
          &:first-child {
            border-left: medium none;
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  .button_scroll.fixed {
    margin-left: -10px;
  }
}

.vertical {
  .header {
    height: 100%;
    padding: 30px 0;
    position: fixed;
    width: 75px;
    z-index: 9999999;
    //    .transition(padding-top 0.5s ease 0s);
    > *, > div > * {
      height: 100%;
    }
    .top-head-right.liona-top-head-right > div {
      margin-bottom: 25px;
      text-align: center;
    }
    #tz-logo-area {
      padding-bottom: 80px;
      text-align: center;
    }
  }
  div.mod-languages {
    margin-right: 10px;
    margin-top: 5px;
    text-align: center;
    ul {
      &.lang-inline li {
        float: left;
        border-left: none;
        margin: 0;
        width: 100%;
        a {
          border-top: 1px solid;
          font-size: 14px;
          text-transform: lowercase;
          margin: auto;
          padding: 5px;
          width: 60%;
          display: block;
        }
        &:first-child a {
          border-top: none;
        }
        &.lang-active a {
          text-transform: uppercase;
        }
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  div.mod-languages {
    margin-right: 0 !important;
  }
  .show_cart {
    float: none !important;
    a {
      float: none !important;
    }
  }
  .nav.navbar-nav.level0 {
    padding-left: 45px;
  }
  .icon_menu_ver {
    color: #fff;
    font-size: 20px;
    left: 10px;
    position: absolute;
    top: 30%;
    display: block;
  }
  .tz-footer {
    padding-top: 15px;
    padding-bottom: 0;
    line-height: 1;
    .loading_portoflio {
      width: 100%;
      text-align: center;
      #tz_append #infscr-loading {
        text-align: center;
      }
    }
    .footer_info {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .custom_text {
    bottom: 0;
    position: absolute;
    .text_vertical {
      bottom: 0;
      .transform(rotate(-90deg));
      transform-origin: 15px 0px 0;
      -ms-transform-origin: 15px 0px 0;
      -webkit-transform-origin: 15px 0px 0;
      font-size: 16px;
      font-weight: bold;
      color: @black;
      min-width: 250px;
    }
  }
  #tz_options .option-combo {
    padding: 0 15px;
  }
}

.menu {
  display: none;
  position: relative;
  z-index: 9999999;
  width: 100%;
  &.active {
    position: fixed;
    top: 0;
  }
}

.text-logo {
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
}

a:focus {
  outline: none;
}

a:focus, a:hover, a:active {
  text-decoration: none;
}

.tz-top-head-right {
  > div {
    display: inline-block;
    float: right;
    padding: 0 10px;
  }
  > .tz-module:first-child {
    padding-right: 0;
  }
}

.header .row {
  position: relative;
}

.btn_filter,
.btn_search,
.search-close {
  cursor: pointer;
}

.search {
  #searchForm {
    > * {
      margin-bottom: 15px;
    }
  }
  .btn-toolbar {
    margin: 0;
    .btn-group {
      margin: 0;
      display: block;
      position: relative;
      width: 100%;
      input {
        width: 100%;
        padding: 5px;
      }
      .btn {
        background: none repeat scroll 0 0 transparent;
        border: 0 none;
        border-radius: 0;
        float: none;
        line-height: 1;
        margin: 0;
        padding: 7px 10px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .phrases, .only {
    .phrases-box, .checkbox {
      input {
        position: relative;
        margin: 0 10px 0 0;
      }
    }
  }
  .search_option {
    .phrases, .only {
      width: 50%;
      float: left;
    }
  }
  #limit_chzn, .chzn-drop {
    width: auto !important;
  }
}

.search-ver {
  display: none;
  .top-search {
    position: relative;
    opacity: 1;
    z-index: 1;
    height: auto;
    background: transparent;
    input.inputbox {
      color: @white;
      background: transparent;
      height: auto;
      padding-top: 15px;
      padding-left: 60px;
    }
    .search-icon {
      color: @white;
      position: absolute;
      top: 35%;
      left: 10px;
    }
    .search-close {
      color: @white;
      top: 30%;
      right: 20px;
      font-size: 18px;
    }

  }

}

.top-search {
  height: 100%;
  position: fixed;
  width: 100%;
  background: @cus_color4;
  z-index: -9999;
  opacity: 0;
  left: 0;
  .transition(all 0.2s linear 0s);
  &.active {
    z-index: 999999;
    opacity: 1;
  }
  .module-inner,
  .module-ct,
  .tz-module {
    height: 100%;
  }
  .module-ct {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .search {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    .form-inline {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    .search-icon {
      font-size: 36px;
      position: relative;
      right: 45px;
      top: 50px;
    }
  }

  input.inputbox {
    border: medium none;
    border-radius: 0;
    width: 100%;
    box-shadow: none;
    vertical-align: top;
    font-weight: 300;
    font-size: 45px;
    color: @black;
    background: transparent;
    height: auto;
    line-height: 45px;
    padding: 0;
    margin: 70px 0 0;
    &:focus {
      box-shadow: none;
    }
  }
  .search-close {
    position: absolute;
    right: 45px;
    top: 45px;
    font-size: 36px;
  }
}

.filter-close {
  position: absolute;
  right: 0;
  top: 0;
}

.search-close,
.filter-close {
  font-size: 14px;
}

.filter-close,
.menu-close {
  cursor: pointer;
}

.filter-icon,
.search-icon,
.menu-icon {
  font-size: 14px;
  cursor: pointer;
}

div.last_item {
  &:before {
    background: none repeat scroll 0 0 black;
    color: black;
    content: "";
    height: 1px;
    left: -45px;
    position: absolute;
    top: 10px;
    width: 50px;
  }
  > span {
    font-size: 18px;
    text-transform: uppercase;
    padding-left: 30px;
  }
}

#tz_options {
  display: block;
  padding: 0 15px;
  position: absolute;
  width: 100%;
  background: @white;
  opacity: 0;
  z-index: -9999;
  .transition(opacity 0.2s ease-out 0s);
  min-height: 100%;
  .option-combo {
    position: relative;
    margin-top: 10px;
  }
  #filter {
    display: inline-block;
    a {
      padding: 0 15px;
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      color: @black_op05;
      &:hover, &:focus, &:active {
        text-decoration: none;
      }
      &.selected, &:hover, &:focus, &:active {
        background: transparent;
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  &.active {
    display: block;
    opacity: 1;
    z-index: 999999;
  }
}

/*---- Portfolio ----*/
.fix {
  .TzArticleMedia {
    img {
      width: auto;
      position: relative;
    }
  }
}

#TzContent {
  margin: -7px;
  width: auto;

  #portfolio {
    &:before, &:after {
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
    }
    .tzoverlay {
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 100;
      .transition(all 0.8s ease 0.1s);
      opacity: 0;
      -webkit-backface-visibility: visible;
    }
  }
  .element {
    background: transparent;
    border: none;
    &:hover {
      overflow: visible;
    }
  }
  .TzInner {
    margin: 7px;
    overflow: hidden;
    position: relative;
    padding: 0;
    border: none;
    .transition(all 0.3s linear 0s);
    &:after {
      background: rgba(0, 0, 0, 0);
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      .transition(all 0.3s linear 0.1s);
      top: 0;
      z-index: 99;
    }
    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.8);
      .TzPortfolioDescription {
        opacity: 1;
        z-index: 9999;
      }
      &:after {
        top: 0;
        background: @black_op05;
      }
      &:before {
        transform: rotate(45deg);
      }
      .TzPortfolioMedia {
        img {
          .transform(scale(1.2));
        }
      }
    }
    .TzPortfolioDescription {
      display: table;
      height: 100%;
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      vertical-align: middle;
      width: 100%;
      opacity: 0;
      padding: 15px;
      .transition(all 0.2s linear 0s);
      .TzPortfolioTitle {
        a {
          font-size: 20px;
          color: @white;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      > * {
        display: table-cell;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        width: 100%;
      }
    }
    .tag {
      a {
        text-transform: capitalize;
        color: @white;
        font-weight: bold;
      }
    }
    .TzPortfolioMedia {
      img {
        position: relative;
        .transition(all 2s ease 0s);
        -webkit-backface-visibility: visible;
        visibility: visible;
        width: 100%;
        height: 100%;
      }
    }

  }
}

.loading_portoflio {
  display: block;
  left: 50%;
  margin: -110px 0 0 -110px;
  position: fixed;
  top: 50%;
  z-index: 99999;
}

#tz_append {
  display: table;
  height: 100%;
  width: 100%;
  > a {
    font-size: 18px;
    text-transform: uppercase;
    color: @black;
  }
  #infscr-loading {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    border: none;
    padding: 0;
    left: 0;
    bottom: 0;
    position: relative;
    font-size: 18px;
    text-transform: uppercase;
    height: 220px;
    width: 220px;
    display: table-cell;
    vertical-align: middle;
    img {
      position: absolute;
      top: 25%;
      left: 25%;
      opacity: 0.05;
    }
  }
}

/*---------- FOoter -----------*/
.footer-hori {
  padding: 15px 15px 0 15px;
}

.loading_portoflio {
  display: inline-block;
}

.tz-footer {
  .custom {
    position: relative;
  }
}

.footer_info {
  float: right;
  color: @black;
  margin-top: 5px;
  text-transform: uppercase;
  a {
    color: @black;
    &:hover {
      color: @black;
    }
  }
}

/* ------ Menu Ver ----*/
.mega-group-title {
  letter-spacing: 1.3px;
}

.menu-aside.tz-ver {
  .transition(all 0.5s ease 0s);
}

.tz-ver {

  #tz-logo-area {
    padding-bottom: 80px;
  }
  #tz-liona-breadcrumbs {
    bottom: 170px;
    .last_item {
      .transform(rotate(-90deg));
      transform-origin: left top 0;
      min-width: 310px;
      span {
        padding: 0 0 0 45px;
      }
    }
  }
  .header > div > div {
    padding: 0;
    &#tz-liona-top-head-right > div {
      margin-bottom: 25px;
    }
  }
  .header, .header > div {
    height: 100%;
    width: 100%;
    margin: 0;
  }
}

.tz-top-head-right {
  margin-top: 5px;
}

/*---- Home ---*/

/* ---- Zoomslide ---- */

.tz_zoomslider {
  position: relative;
  .tz_cus_content {
    display: table;
    width: 100%;
    height: 100%;
    z-index: 111;
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    > div {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
    }
    .custom_image {
      margin-bottom: 50px;
    }

    .cus_style1, .cus_style2, .tz_custom_button {
      text-transform: uppercase;
      line-height: 1.2em;
    }
    .cus_style1 {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #ffffff;
      display: inline-block;
      font-size: 18px;
      padding: 5px 15px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .cus_style2 {
      background: transparent;
      color: @black;
      font-size: 60px;
      margin: 15px auto;
    }
    .tz_custom_button {
      margin-top: 20px;
      color: @black;
      font-size: 18px;
      border: 2px solid @black;
      padding: 10px 30px;
      display: inline-block;
      position: relative;
      overflow: hidden;
      span {
        position: relative;
        z-index: 999;
      }
      &:hover {
        color: #FFffff;
        &:after {
          height: 600%;
          opacity: 1;
        }
      }
      &:after {
        content: "";
        position: absolute;
        transition: all 0.5s ease 0s;
        background: none repeat scroll 0 0 #000;
        width: 100%;
        height: 0;
        left: 50%;
        opacity: 0;
        top: 50%;
        .transform(translateX(-50%) translateY(-50%) rotate(45deg));
      }
    }

  }
}

/*----- Supersize ----*/
.bg-overlay-supersize {
  background: url("../../../images/pattern.png") repeat;
  height: 100%;
  position: absolute !important;
  width: 100%;
  z-index: 1;
}

.text3 .revo_text_2 {
  color: @white;
}

.tz_supersized {
  position: relative;
  overflow: hidden;
  #supersized {
    top: auto;
    position: relative;
    left: auto;
    padding: 0;
    right: auto;
  }
  #prevslide, #nextslide {
    background: transparent !important;
    font-size: 48px;
    color: @black;
    line-height: 1;
    width: auto;
    height: auto;
    margin: 0;
    opacity: 1 !important;
    &:hover, &:focus {
      color: @white;
    }
    &:active {
      margin-top: 0 !important;
    }
    z-index: 1111;
  }
  #tz_supersized.tz_liona_supersized {
    display: table;
    width: 100%;
    height: 100%;

    position: absolute;
    .slide-des {
      display: table-cell;
      bottom: auto;
      left: auto;
      top: auto;
      right: auto;
      vertical-align: middle;
      //      text-align: right;
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;
      padding: 0 30px;
      .slidecaption {
        font: inherit;
        margin: 0;
        float: none;
        text-shadow: none;
        background: transparent !important;
        display: block !important;
        padding: 0 !important;
        overflow: visible !important;
        span, p {
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          color: @white;
        }
        p {
          font-size: 48px;
          padding: 0 10px;
        }
        span {
          padding: 3px 10px;
          font-size: 18px;
          top: -3px;
          position: relative;
        }
        &#slidecaption {
          padding-bottom: 25px !important;
        }
        &#slidecaption1 {
          padding-top: 20px !important;
        }
      }
    }
    .slider-control {
      position: absolute;
      right: 75px;
      height: auto;
      width: auto;
      top: 75px;
      z-index: 1111;

      #prevslide {
        padding-right: 25px;
      }
      #nextslide {
        padding-left: 25px;
      }
    }

  }
  #thumb-tray {
    position: absolute;
    z-index: 111;
    ul#thumb-list {
      margin: 0;
      li, img {
        min-height: 160px;
        width: 240px;
      }
      li {
        position: relative;

        &:after {
          .transition(all 0.5s ease 0s);
        }
        &:hover:after, &:hover:before {
          content: url("../../../images/Plus.png");
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 10;
          font-size: 60px;
          //          font-family: tzicon;
          padding-top: 40px;
          color: @white;

        }
      }
    }
  }
}

.slider-control {
  span.music_icon, span.close-music, span.music {
    background: none !important;
  }
}

ul#supersized {

  li {
    left: auto !important;
    background: transparent;
    top: auto;
    bottom: auto;
    position: absolute;
  }
}

/* ---- Video -----*/
#canvas {
  display: block;
}

.TzStatistic {
  overflow: hidden;

  position: relative;
  video {
    width: 100%;
    position: relative;
  }
  .video-content, .bg-overlay {
    display: table;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .tz-video-content {
    display: table-cell;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
}

/* --- Revolution --- */
.tp-rightarrow.custom,
.tp-leftarrow.custom {
  top: inherit !important;
  background: none !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  &:before {
    font-family: tzicon;
    color: #FFffff;
    font-size: 60px;
  }
  &:hover:after, &:hover:before {
    color: @black;

  }

}

.tp-leftarrow.custom {
  &:before {
    content: '\e761';
  }
}

.tp-rightarrow.custom {
  &:before {
    content: '\e762';
  }
}

.tp-bullets.custom {
  top: 75px !important;
  right: 75px !important;
}

.bullet {
  display: inline-block;
  margin: auto 7px;
  &.selected:before {
    color: rgba(0, 0, 0, 1);
  }
  &:before {
    content: '\f111';
    color: rgba(0, 0, 0, 0.5);
    font-family: FontAwesome;
  }
}

.home-slide-bg {
  overflow: hidden;
}

.fullscreen-container {
  height: 100% !important;
}

.tz-revo {
  height: 100%;
  .module-inner {
    height: 100%;
    .module-ct {
      height: 100%;
    }
  }
}

.revo_text_1 {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8) !important;
  color: #fff;
  font-size: 18px !important;
  line-height: 1 !important;
  padding: 6px 15px !important;
  text-transform: uppercase;
  font-weight: bold;
}

.revo_text_2 {
  font-size: 60px !important;
  line-height: 1 !important;
  text-transform: uppercase;
  color: @black;
}

.revo_button_1 {
  border: 2px solid @black !important;
  color: #000 !important;
  font-size: 18px !important;
  padding: 9px 25px !important;
  text-transform: uppercase;
  line-height: 1 !important;
  font-weight: bold;
}

/*----- Slider Shop ----*/
#mycursor {
  cursor: none;
  width: 80px;
  height: 70px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 10000;
}

.scroll-information {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
  padding: 70px 0;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.va-container {
  &.top {
    .va-slice {
      cursor: url("../../../images/scrolling-up-white.png"), url("../../../images/scrolling-up-white.png"), auto;
    }
  }
  &.bottom {
    .va-slice {
      cursor: url("../../../images/scrolling-down-white.png"), url("../../../images/scrolling-down-white.png"), auto;
    }
  }

}

.ca-container {
  height: 0;
  width: 0;
  &.tz-left {
    .ca-item {
      cursor: url("../../../images/scrolling-left.png"), url("../../../images/scrolling-left.png"), auto;
    }
  }
  &.tz-right {
    .ca-item {
      cursor: url("../../../images/scrolling-right.png"), url("../../../images/scrolling-right.png"), auto;
    }
  }
  .category_info > div .category-title {
    font-weight: bold;
    font-size: 48px;
  }
  .category_info > div .total {
    font-weight: bold;
  }
}

#va-container {
  overflow: hidden;
  margin-top: -15px;
}

.va-wrapper {
  background: transparent !important;
}

.va-slice {
  padding: 7px 0;
}

.ca-item,
.va-slice {
  &:hover {
    .category_info > div {
      .category-title:after {
        border-bottom-color: #FFffff;
      }
    }
  }
}

.ca-item {

}

.category-image,
.category_item {
  width: 100%;
  height: 100%;
  position: relative;
}

.category-image {
  overflow: hidden;
  img {
    position: relative;
    .transition(all 0.3s ease 0s);
  }
}

.va-slice {
  &:hover {
    .category-background {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
    }
    .category_info > div {
      i, .category-title, .total {
        color: @white;
      }
    }
  }

}

.category_info {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  &:hover {
  }
  > div {
    .caption_image {
      margin: auto;
      height: 50px;
      width: 50px;

    }
    .category-title, i {
      font-size: 60px;
      text-transform: uppercase;
    }
    i {
      display: block;
    }
    .category-readmore {
      color: @black;
    }
    .category-title {
      margin: 20px 0 0 0;
      position: relative;
      display: inline-block;
    }
    .total {
      text-transform: uppercase;
      margin: 0;
      padding-top: 25px;
      color: @black;
    }
  }
}

.category-background {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.7);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  //  z-index: 1111;
  .transition(all 0.3s linear 0s);
  &:hover {

  }
}

/*------- Cart Shop -------*/
.vmCartModule {
  position: relative;
}

.horizontal {
  .total_products {
    right: -7px;
    top: -7px;
  }
}

.total_products {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #ff3b00;
  color: @white;
  font-size: 7px;
  padding: 4px 5px;
  border-radius: 50%;
}

.show_cart {
  float: left !important;
  a {
    color: @black;
    i {
      font-size: 14px;
    }
  }
}

/*------- Shop horizontal ----*/
.vertical {
  .ca-item {
    .category_item {
      padding-right: 10px;
    }
    .category_info {
      padding-right: 10px;
    }
  }
}

.horizontal {
  .ca-container {
    margin: 0 -5px;
  }
  .ca-item {
    .category_item {
      padding: 0 5px;
    }
  }
}

.ca-item {
  .category-background {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.35);
    margin: 0 10px;
  }
  &:hover {
    .category-background {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
    }
  }
}

.tz-footer-horizontal {
  position: fixed;
  right: 0;
  .footer_info {
    margin: 0;
    position: fixed;
    right: 50px;
    text-align: center;
    top: 30px;
    transform: rotate(-90deg);
    transform-origin: right top 0;
  }
}

/*----- Portoflio Item ----*/

.owl-stage {
  margin-left: -25px;
}

.tz_portfolio_image {
  text-align: center;
}

.detail {

  .tz-right {
    padding-left: 0;
  }
}

.TzArticleTitle {
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  margin: 2px 0 6px 0;
  color: @black;
  font-weight: bold;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  overflow: hidden;
  position: static;
  li {
    float: left;
    margin: 0;
    width: 25%;
    img {
      cursor: pointer;
      display: block;
      opacity: 0.7;
      width: 100%;
    }
  }
}

.tz-portfolio-media {
  .tz_portfolio_video {
    margin: 40px 35px;
  }
  .tz_portfolio_image_gallery {
    position: relative;
    margin: 40px -15px;
  }
  .tz_portfolio_image {
    margin: 40px 0;
    padding: 0 30px;
    img {
      cursor: url("../../../images/zoom-white.png"), url("../../../images/zoom-white.png"), auto;
    }
  }
  .tz_audio {
    margin: 40px 25px;
  }
}

.TzArticleMedia {
  &.tz-right {
    .owl-item {
      cursor: url("../../../images/scroll-no-text-right.png"), url("../../../images/scroll-no-text-right.png"), auto;
    }
  }
  &.tz-left {
    .owl-item {
      cursor: url("../../../images/scroll-no-text-left.png"), url("../../../images/scroll-no-text-left.png"), auto;
    }
  }
}

.tz-slide-navigation {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999999;
  background: transparent;
}

.tz_portfolio_image_gallery {
  .owl-stage-outer {
    height: 100%;
  }
  .owl-controls {
    .owl-nav {
      display: none;
      padding-top: 10px;
      .owl-prev, .owl-next {
        display: inline-block;
        i {
          font-size: 16px;
          color: @white;
        }
      }
    }
  }
  .owl-item {
    overflow: hidden;
    margin: 0 10px !important;
    > div {
      > div::before {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        .transition(width 0.5s ease 0s);
      }
    }
    &.center {
      cursor: url("../../../images/zoom-white.png"), url("../../../images/zoom-white.png"), auto !important;
      > div {
        > div::before {
          width: 0;
        }
      }
      .slide-overlay, .tz-slide-navigation {
        display: none;
      }
    }
    .slide-overlay {
      background: rgba(0, 0, 0, 0.7);
    }
    &.center {
      .slide-overlay {
        background: transparent;
      }
    }
    > div {
      position: relative;
    }
  }
  .slide-overlay {
    .transition(all 0.3s linear 0s);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999;
  }
}

.flexslider {
  background: transparent !important;
  .slides {
    img {
      width: 100%;
    }
  }
  &.flex-fullscreen {
    .slides {
      img {
        width: auto;
        position: relative;

      }
    }
  }
}

#slider.flexslider {
  padding: 0;
  margin: 0;
}

.flex-direction-nav li .flex-next,
.flex-direction-nav li .flex-prev {
  background: @white;
  width: auto;
  padding: 0 10px;
  &:before {
    font-family: tzicon;
    font-size: 20px;
    color: @black;
  }
}

.flex-direction-nav li .flex-next {
  right: -30%;
  &:before {
    content: '\e762';
  }
}

.flex-direction-nav li .flex-prev {
  left: -31%;
  &:before {
    content: '\e761';
  }
}

.fullscreen {
  color: @white;
  font-size: 20px;
  position: absolute;
  right: -25%;
  top: 30px;
  cursor: pointer;
  &:before {
    content: '\f065';
    font-family: FontAwesome;
  }
  &:hover {
    color: @white;
  }
  &.compress:before {
    content: '\f066';
    font-family: FontAwesome;
  }
}

.type_media {
  i {
    margin-right: 10px;
  }
}

.button_scroll {
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 0;
  left: 50%;
  margin-left: -11px;
  cursor: pointer;
  &.fixed {
    position: fixed;
    bottom: 30px;
    margin-left: 11px;
    z-index: 9999999;
  }
  > a {
    bottom: -10px;
    color: #000;
    font-size: 15px;
    line-height: 1;
    margin-left: 30px;
    position: absolute;
    z-index: 11111;
  }
}

.button_scroll:after, .TzArticleMedia:before {
  bottom: 0;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

}

.button_scroll:after {
  border-color: rgba(241, 241, 241, 0);
  border-bottom-color: @white;
  border-width: 22px;
  margin-left: -24px;
  content: "";
}

.button_scroll:before {
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: @white;
  border-width: 22px;
  margin-left: -24px;
  content: "";
}

.flex-direction-nav li a {
  z-index: 111;
}

.fulltext p {
  line-height: 1.92;
  padding: 12px 0;
  color: @black;
  &.background_text {
    background: #21759b;
    text-align: center;
    font-size: 30px;
    color: @white;
    line-height: 1.2em;
    padding: 92px 0 106px;
    margin: 25px 0 0;
  }
  &.alt_title {
    font-size: 13px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.2;
  }
}

.TzArticleTag {
  .title {
    color: rgba(0, 0, 0, 0.4);
    font-size: 13px;
    display: inline-block;
    margin-right: 5px;
  }
  span {
    border-radius: 2px;
    .label {
      color: @white;
      background: rgba(210, 210, 210, 1);
      font-size: 12px;
      padding: 1px 5px;
      font-weight: 300;
    }
  }
}

.navi_article {
  .pagenav {
    border-width: 1px 0;
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    margin: 0;
    padding: 67px 0;
    font-size: 18px;
    position: relative;
    background: transparent;
    a {
      color: #34373b;
      text-transform: uppercase;
      font-size: 14px;
    }
    span {
      display: block;
      color: #34373b;
      font-size: 14px;
    }
    > div {
      > * {
        position: absolute;
      }
      a {
        top: 40px;
      }
      span {
        top: 67px;
        max-width: 50%;
      }
    }
    .previous {
      > * {
        left: 0;
      }
    }
    .next {
      > * {
        right: 0;
        text-align: right;
      }
    }
  }
}

div#comments {
  padding-top: 0;
  p {
    line-height: 1.5em;
    margin-right: 10px;
  }
  .count_cm {
    margin-top: 7px;
    padding-bottom: 4px;
  }
  h3.comment_header {
    float: left;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 25px;
    margin: 0;
  }
  .leave_comment {
    line-height: 1;
    margin: 0;
    position: relative;
    top: -5px;
    &:hover {
      color: #462800;
    }
  }
  .comments_form {
    position: relative;
    top: -4px;
  }
  a {
    color: #462800;
    &:hover {
      color: #462800 !important;
    }
  }
  div.comment-avatar {
    margin-right: 10px;
    margin-top: 0;
    img {
      width: 59px;
    }
  }
  .comment-content {
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    padding: 0 0 0 70px;
    color: @black;
  }
  div#comments-list-0 {
    margin-left: 0;
    margin-top: 40px;
    #comments-list-1 {
      padding-left: 100px;
    }
    #comments-list-2 {
      padding-left: 200px;
    }
    .comments-list {
      margin-top: 35px;
      border-top: 1px solid #ebebeb;
      padding-top: 35px;
      margin-left: 0;
    }
    .comment_item {
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(70, 40, 0, 0.1);
      margin-bottom: 45px;
    }
    #comments-list-1 {
      margin: 0;
      padding-top: 0;
      border: none;
      .comment_item {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(70, 40, 0, 0.1);
        margin-bottom: 45px;
      }
    }
  }
  #comments-inline-edit {
    border: none;
  }
  .comment-author {
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    color: #462800;
    font-size: 18px;
    position: relative;
    top: -1px;
    .j-date {
      margin: 0 10px;
    }
    .j-date,
    .j-author {
      color: @black;
    }
    span.comment-meta {
      float: right;
      color: #999999;
      font-size: 14px;
      font-weight: 300;
      position: relative;
      top: -4px;
      a {
        color: @cus_color1;
        line-height: 22px;
        font-weight: 300;
        font-size: 14px;
        margin: 0 10px;
        &.comment-quote {
          margin-right: 0;
        }
        &:hover {
          color: @cus_color1 !important;
        }
      }
    }
  }
  .quote, blockquote, br {
    display: none;
  }
  #comments-list-footer {
    margin: 40px 0 0 0 !important;
    a {
      &:hover {

      }
    }
  }
  #comments-form {
    margin-top: 40px;
  }
  #comments-form .form-group {
    position: relative;
    margin-bottom: 10px;
  }
  #comments-form .btn {
    background: @black;
    padding: 8px 22px !important;
    line-height: 1;
    margin-top: 15px;
    color: @white;
    text-transform: uppercase;
    border-radius: 0;
    font-size: 12px;
    position: relative;
    left: 15px;
  }
  #comments-form .form-group i {
    color: rgba(180, 180, 180, 1);
    font-size: 16px;
    line-height: 36px;
    position: absolute;
    right: 15px;
    top: 0;
  }
  #comments-form {
    margin-top: 40px !important;
    #comments-form-cancel {
      .btn {
        background: @white;
        padding: 5px 22px !important;
        color: #462800;
        .transition(all 0.3s linear 0s);
        &:hover {
          background: #462800;
          color: @white;
        }
      }
    }
  }
  #comments-form .form-group .form-control {
    background-color: @white;
    background-image: none;
    border: 1px solid rgba(70, 40, 0, 0.1);
    .border-radius(0);
    .box-shadow(none);
    display: block;
    font-size: 13px;
    height: 40px;
    line-height: 22px;
    padding: 6px 12px !important;
    font-weight: 400;
    width: 100%;
    color: #462800;
    input {
      min-height: 40px;
    }
    textarea {
      min-height: 120px;
    }
  }
  #comments-form .form-group .form-control::-moz-placeholder {
    color: rgba(180, 180, 180, 1);
  }
  #comments-form textarea.form-control::-moz-placeholder {
    color: rgba(180, 180, 180, 1);
  }
  #comments-form textarea.form-control {
    background-color: @white;
    background-image: none;
    border: 1px solid rgba(70, 40, 0, 0.1);
    .border-radius(0);
    .box-shadow(none);
    display: block;
    font-size: 14px;
    line-height: 22px;
    padding: 6px 12px !important;
    font-weight: 400;
    width: 100%;
    max-width: none !important;
    color: inherit;
    min-height: 140px;
  }
  .grippie {
    display: none;
  }
  .title_comment_form {
    font-size: 24px;
    font-weight: 700;
    color: #462800;
  }
  #comments_content {
    margin-bottom: 30px;
  }
}

.TzPortfolioItemPage {
  .info > .container > .tz-row {
    vertical-align: middle;
    display: inline-block;
  }
}

.TzItemPage {
  .info > .container > .tz-row {
    margin: 0 0 20px;
    span {
      font-weight: bold;
    }
  }
}

.info {
  > .container {
    text-align: center;
    > .tz-row {

    }
  }
  .tz-row > div {
    padding: 0 3px;
  }
  a {
    color: @black_op05;
  }
  span {
    color: @black_op05;
    > span, i {
      color: rgba(133, 133, 133, 0.5);
    }
  }
  .TzArticleCategory {

  }
}

/* ---- Blog ---- */
.blog-article {
  .TzItem {
    padding: 55px 0;
    margin-bottom: 0;
  }
}

.row-separator {
  border-top: 1px dashed rgba(52, 55, 59, 0.1);

}

.tz-quote {

}

.view-users {
  .tz-right {
    padding-right: 30px;
  }
}

.TzQuote {
  padding-top: 25px;
  .text {
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
  }
  .author {
    padding-top: 19px;
    color: @black;
    font-size: 18px;
    margin-bottom: -3px;
  }
}

.TzUser {
  text-align: center;
  .TzUserInner {
    background: @white;
    width: 70%;
    display: inline-block;
    text-align: left;
    padding: 60px 75px;
    .tz_portfolio_user {
      margin: 0 0 60px 0;
      .AuthorSocial {
        bottom: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        background: rgba(0, 0, 0, 0.57);
        padding: 5px 0;
        a:hover {
          i {
            color: @cus_color1;
          }
        }
        i {
          color: @white;
        }
      }
      .AuthorAvatar {
        position: relative;
        padding: 0 !important;
        margin-right: 30px;
        min-height: 150px;
        min-width: 150px;
        img {
          width: 100%;
          max-width: none;
        }
      }
      .media-heading {
        margin: 30px 0 20px;
        a {
          position: relative;
          color: @black;
          font-weight: bold;
        }
      }
    }
    .TzUserMedia {
      img {
        width: 100%;
      }
    }
    .TzBlogTitle {
      margin: 0;
      a {
        color: @black;
        font-weight: bold;
        &:hover {
          color: @black;
        }
      }
    }
    .TzLeading {
      padding: 55px 0;
    }

    .TzUserArticleInfo {
      padding: 25px 0 0;
      > span {
        color: #adaeaf;
        > span {
          color: @black_op05;
          > span {
            color: @black_op05;
          }
        }
        a {
          color: @black_op05;
        }
      }
      .blog_type_article {
        color: @black_op05;
        i {
          margin-right: 5px;
          color: #adaeaf;
        }
      }
    }
    .TzDescription {
      padding: 20px 0 0;
      p {
        line-height: 2em;
      }
    }
    .TzUserMedia {
      padding: 20px 0 0;
    }
    .TzReadmore {
      color: @cus_color1;
      padding: 13px 0 0;
      &:hover {
        color: @black;
        i {
          color: @black;
        }
      }
    }
  }
}

.TzBlog {
  text-align: center;
  .TzBlogInner {
    background: @white;
    width: 70%;
    display: inline-block;
    text-align: left;
    padding: 60px 75px;
    .info_category {
      .hr {
        border-top: 1px dashed rgba(52, 55, 59, 0.1);
        margin: 60px 0 0;
      }
      .TzCategoryTitle {
        margin: 0 0 25px 0;
        font-weight: bold;
      }
    }
    .TzBlogMedia {
      img {
        width: 100%;
      }
      .flex-direction-nav {
        a {
          text-indent: 0;
          color: @black;
          height: auto;
          width: auto;
          top: 48%;
          padding: 5px;
          background: @white;
          &:hover {
            background: transparent;
          }
          &:before {
            font-size: 20px;
            font-family: tzicon;
            color: @black;
          }
          &.flex-prev {
            left: 15px;
          }
          &.flex-next {
            right: 15px;
          }
        }
        .flex-prev:before {
          content: '\e761';

        }
        .flex-next:before {
          content: '\e762';
        }
      }
    }
    .TzBlogTitle {
      margin: 0;
      a {
        color: @black;
        font-weight: bold;
        &:hover {
          color: @black;
        }
      }
    }
    .TzVote {
      display: inline-block;
    }
    .blog_type_article {
      color: rgba(0, 0, 0, 0.5);
      i {
        margin-right: 5px;
        color: rgba(173, 174, 175, 0.5);
      }
    }

  }
  .TzArticleBlogInfo {
    padding: 15px 0 0;
    > span {
      color: rgba(173, 174, 175, 0.5);
      > span, a {
        color: rgba(0, 0, 0, 0.5);
      }
      &.TzPortfolioCommentCount, &.TzBlogCreate {
        > span {
          color: rgba(173, 174, 175, 0.5);
          > span {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    .TzVote {
      .content_rating {
        .rating {
          a {
            color: @black_op05;
          }
        }
      }
    }
    .TzBlogCategory > span,
    .TzPortfolioCommentCount span > span,
    .TzBlogCreate span > span,
    .TzBlogCreatedby > span,
    .blog_type_article {
      //      font-weight: bold;
    }
  }
  .TzIcon {
    position: relative;
    .btn-group {
      position: absolute;
      right: 0;
      top: -30px;
      float: none !important;
    }
  }
  .TzDescription {
    padding: 20px 0 0;
    p {
      line-height: 2;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .TzBlogMedia {
    padding: 20px 0 0;
    margin-bottom: 0;
  }
  .TzReadmore {
    padding: 13px 0 0;
    color: @cus_color1;
    &:hover {
      color: @black;
    }
  }
  .TzTag {
    padding-top: 25px;
    .title-tag {
      color: rgba(52, 55, 59, 0.4);
      font-size: 12px;
    }
    .blog-tag {
      font-size: 11px;
      background: #d2d2d2;
      color: @white;
      margin: 0;
      border-radius: 2px;
      padding: 1px 5px;
      &:hover {
        background: @white;
        color: #d2d2d2;
      }
    }
  }
  .TzArticleBlogInfo {
    &.tz-link, &.tz-quote {
      padding: 0;
    }
  }
  .TzLink {
    padding-top: 25px;
    .title {
      margin: 0;
      a {
        color: @cus_color1;
      }
    }
  }
}

.pagination {
  margin: 0;
  padding-top: 60px;
  line-height: 2;
  ul {
    padding: 0;
    margin-left: -7px;
    li {
      display: inline-block;
      margin: 0 7px;
      &.active {
        a {
          color: @cus_color1;
        }
      }
      a {
        background: rgba(230, 230, 230, 1);
        padding: 6px 12px;
        margin: 0;
        border: none;
        border-radius: 3px;
        color: @black;
        i {
          margin: 0 5px;
          top: 1px;
          position: relative;
        }
        &:hover {
          color: @cus_color1;
        }
      }
      &.disabled {
        display: none;
      }
    }
  }
}

/*---- Categories Tz Portfolio ------*/
.tz-categories-menu {
  position: relative;
  .menu-vm {
    padding: 0;
    margin: 0;
    li {
      margin: 10px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: rgba(0, 0, 0, 0.7);
        font-size: 15px;
        line-height: 1.2;
        font-weight: 300;
        &:hover {
          color: @cus_color1;
        }
      }
    }
  }
}

/*----- Newsletter ----*/
.newsletter_title {
  margin-bottom: 30px;
}

.input-box {
  margin-bottom: 30px;
}

.sub {
  background: @black;
  color: @white;
  border-radius: 0;
  font-size: 12px;
  &:hover {
    color: @cus_color1;
  }
}

.txt-box {
  background: none repeat scroll 0 0 #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 11px;
  padding: 10px;
  width: 100%;
  color: rgba(0, 0, 0, 0.3);
  line-height: 2;
}

/*---- Categories Menu VM----*/
.vm-categories-menu {
  position: relative;
  > h4 {
    color: @white;
    text-transform: uppercase;
    padding-bottom: 22px;
    line-height: 1.4;
    margin: 0;
  }
  .menu-vm {
    padding: 0;
    margin: 0;
    li {
      margin: 10px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        a {
          color: @cus_color1;
        }
      }
      a {
        color: @black;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 300;

      }
    }
  }
}

/*----- Best Seller ----*/
.cell-4, .cell-8, .cell-5, .cell-7 {
  float: left;
  padding: 0 15px;
  position: relative;
}

.cell-7 {
  width: 58.3333%;
}

.cell-5 {
  width: 41.6667%;
}

.cell-4 {
  width: 33.3333%;
}

.cell-8 {
  width: 66.6667%;
}

.tz-vm-best-seller {
  .title-best-seller {
    margin-top: 15px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .item {
    padding: 15px 0;
    width: auto;
    &:last-child {
      padding-bottom: 0;
    }
    .vm-title-product {
      font-size: 15px;
      font-weight: bold;
      color: @black;
      display: block;
      width: 100%;
      text-align: left;
      &:hover {
        color: @cus_color1;
      }
    }
  }
  .vm-price-value > span {
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: left;
  }
  img {
    width: 100%;
  }
}

/*---- Shop Detail ----*/

.view-productdetails {
  .sidebar {
    .tz-right {
      padding-left: 0;
    }
    .tz-main {
      padding-right: 0;
    }
    .vm-product-details-container {
      padding-right: 60px;
      padding-left: 60px;
    }
  }
}

.product-field-type-R {
  margin: 0;

}

#fancybox-wrap {
  padding: 0;
  #fancybox-bg-nw,
  #fancybox-bg-n,
  #fancybox-bg-w {
    background: @white;
  }
  #fancybox-title {
    margin: 0 !important;
  }
}

.slick-slider {
  .product-field-display {
    padding: 0 15px;

    img {
      width: 100%;
    }
  }
}

.title-related-product-vm {
  text-transform: uppercase;
  margin: 30px 0 35px;
}

.product-related-products {
  border: none;
  padding: 0;
}

div.product {
  min-width: 0;
}

.vm-hr {
  border-top: 1px solid #dfe9e9;
  margin: 25px 0;
}

.vm-detail-category {
  color: #919191;
  padding-bottom: 25px;
  a {
    color: @black;
    &:hover {
      color: @black;
    }
  }
}

.additional-images {

  margin: 45px 0;
  img {
    width: auto;
    margin-right: 15px;
  }
}

.vm-social-network {
  margin-bottom: 20px;
  .sh {
    font-size: 13px;
    color: #939393;
  }
  ul {
    display: inline-block;
    li {
      display: inline-block;
      margin: auto 4px;
      a {
        color: @black;
        font-size: 18px;
      }
    }
  }
}

.no-sidebar {
  .vm-product-details-container {
    padding-left: 75px;
    padding-right: 75px;
  }
}

.sidebar {
  .vm-product-details-container {
    padding-left: 60px;
    padding-right: 45px;
  }
}

.vm-product-container {
  .vm-product-media-container {
    .transition(all 0.5s ease 0s);
    left: 0;
    padding: 30px 30px 30px 75px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    &.vm-fullscreen {
      //      width: 100%;
    }
  }

  .vm-product-details-container {
    width: 50%;
    position: relative;
    .transition(all 0.5s ease 0s);
    &.width_auto {
      width: 0 !important;
      padding: 0 !important;
      opacity: 0;
    }
  }
  .main-image {
    background: @black;
    height: 100%;
    position: relative;
    width: 50%;
    .transition(all 0.5s ease 0s);
    &.width_auto {
      width: 100% !important;
    }
    img {
      height: 100%;
      max-height: none;

    }
  }
  .product-short-description {
    padding: 20px 0;
  }
  .vm-product-details-container {

    padding-top: 60px;
    > h1 {
      margin-top: 0;
    }
  }
  .product-short-description {
    margin: 0;
    font-size: 14px;
  }

  .product-fields, .addtocart-bar {
    display: inline-block;
  }
  .size {
    display: inline-block;
    .product-fields {
      position: absolute;
      top: 0;
    }
  }
  .spacer-buy-area {
    padding: 20px 0;
    position: relative;
    .addtocart-area {
      min-height: 75px;
      margin: 0;
    }
    .product.js-recalculate {
      position: absolute;
    }
  }

  .addtocart-bar {
    margin: 0;
    .addtocart-button {
      background: #e85d33;
      padding: 5px 10px;
      color: @white;
      font-size: 13px;
      .addtocart-button {
        border: none;
        box-shadow: none;
        padding: 0;
      }
    }
    .quantity-product {
      position: relative;
      padding-bottom: 15px;
      display: inline-block;
      margin-right: 10px;
    }
    .quantity-box {
      input {
        box-shadow: none;
        background: transparent;
        border-radius: 0;
        border-color: #f1f1f1;
      }
    }
    span.quantity-controls {
      width: 30px;
      height: 30px;
      position: relative;
      background: #f1f1f1;
    }
    .js-recalculate {

      .quantity-controls {
        background: none;
        &:after {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          color: #b9b9b9;
          padding-left: 8px;
          font-size: 26px;
        }
      }
      .quantity-minus {
        &:after {
          content: '-';
          left: 0;
          margin-top: -2px;
        }
      }
      .quantity-plus {
        &:after {
          content: '+';
          margin-top: -3px;
          right: 0;
        }
      }
    }

  }
  .chzn-container-single {
    min-width: 200px !important;
    .chzn-drop {
      min-width: 200px !important;
      border-color: rgba(223, 223, 233, 1);
      border-top: 1px solid #dfdfe9;
      font-size: 12px;
    }
    .chzn-single {
      box-shadow: none;
      background: none;
      border-radius: 0;
      height: auto;
      position: relative;
      padding: 7px 50px;
      border-color: rgba(223, 233, 233, 1);
      font-size: 12px;
      line-height: 1.2em;
      div {
        width: 30px;
        border-left: 1px solid rgba(223, 233, 233, 1);
        b {
          background: none;
          &:after {
            content: '\e760';
            position: absolute;
            font-family: tzicon;
            font-size: 8px;
            left: 10px;
            top: 7px;

            color: @color_174;
          }
        }
      }
      &.chzn-single-with-drop div b:after {
        content: '\e75f';
      }
      &:after {
        content: "Size:";
        height: 50%;
        left: 15px;
        position: absolute;
        top: 7px;
        width: 30px;
        color: @color_174;
      }
    }
  }
}

.type-status {
  position: absolute;
  top: 30px;
  left: 30px;
  .product-fields {
    .hot, .sales, .new {
      border-radius: 50%;
      padding: 15px;
      text-transform: uppercase;
    }
    .product-field {
      margin: 0 10px 0 0;
      width: auto;
      color: @white;
    }
    .sales {
      background: @black;
    }
    .hot {
      background: #cf3000;
    }
    .new {
      background: #4ecf00;
    }
  }
}

.rating {
  label {
    i:hover {
      color: @cus_color1;
    }
  }
}

.customer-reviews {
  margin: 0;
  h4, .normal, .highlight {
    border-color: @cus_color2;
    border-radius: 0;
  }
  .normal, .highlight {
    background: none;
  }
  .list-reviews {
    .date {
      border: none;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 0;
      float: none;
    }
  }
}

.vm-price-value {
  > span {
    font-size: 18px;
    margin: 0;
    color: @black;
    font-weight: bold;
  }
}

.button-toggle {
  background: @white;
  border-radius: 3px 0 0 3px;
  height: 40px;
  right: 0;
  line-height: 1;
  margin-left: -15px;
  padding: 12px 7px;
  position: absolute;
  top: 50%;
  width: 15px;
  margin-top: -20px;
  .close-open {

    &.vm-close {
      position: absolute;
      left: -2px;
      &:before {
        .transform(rotate(-90deg));
      }
    }
    &:before {
      background: none;
      content: '\e6c2';
      font-family: tzicon;
      display: inline-block;
      .transform(rotate(90deg));
      color: @black;
    }
  }
}

div.product {
  border: none;
  margin: 0;
  .spacer {
    padding: 0 !important;
    overflow: hidden;
    .type-status {
      z-index: 1;
    }
    .vm-product-media-container {
      img {
        max-width: none;
        max-height: none;
        position: relative;
      }
    }
    .vm-details-button {
      display: inline-block;
      a {
        background: transparent !important;
        border: none;
        border-radius: 0;
        padding: 0;
        &:hover {
          color: @black;
        }
      }
    }
  }
}

.tz-add-to-cart {
  display: inline-block;
  .addtocart-area {
    height: auto;
    margin: 0;
    .size {
      display: none;
    }
    .addtocart-bar {
      bottom: 0;
      position: relative;
      text-align: center;
      .quantity-product, br {
        display: none;
      }
      .addtocart-button {
        i {
          display: none;
        }
        &:before {
          content: "\e67e";
          font-family: tzicon;
          font-size: 20px;
          position: absolute;
          z-index: -1;
          color: @black;
        }
        .addtocart-button {
          background: none repeat scroll 0 0 transparent;
          border: medium none;
          text-indent: -999em;
          padding: 9px;

        }
      }
    }

  }
}

.vm-category-title-product {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
  bottom: 0;
  margin: 0 !important;
  padding: 25px 0 !important;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
  font-size: 14px;
  a {
    color: @black;
    &:hover {
      color: @black;
    }
  }
}

.vm-info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(255, 255, 255, 0.8);
  .transform(scale(0.5));
  .tb-cell {
    padding: 0 20px;
    > *, .product-price {
      height: auto !important;
      float: none;
      text-align: inherit;
      padding: 0 10px;
    }
    h2 {
      text-transform: uppercase;
      a {
        color: @black;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .product-price {
      margin: 13px 0;
      line-height: 1;
      > div > span {
        font-size: 24px !important;
        color: @black !important;
      }
    }
  }
  .vm-details-button {
    a {
      border-color: @cus_color2;
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 18px;
      width: auto;
      max-width: none;
      color: @black;
      background: @white !important;
      &:hover {
        background: @cus_color2 !important;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.vm-product-media-container {
  margin: 0;
  position: relative;
  .additional-images {
    position: absolute;
    bottom: 0;
    margin: 0;
    text-align: center;
    left: 50%;
    display: none;
    .img_thumb_vm {
      background: @white;
      padding: 15px;
    }
    .floatleft {
      float: none;
      display: inline-block;
      img {
        margin-left: 15px;
        margin-right: 0;
      }
      &:first-child {
        img {
          margin-left: 0;
        }
      }
    }
  }
}

.product {
  .vm-category-title-product,
  .vm-info {
    .transition(all 0.3s ease 0s);
  }
  &:hover {
    .vm-category-title-product {
      opacity: 0;
    }
    .vm-info {
      .transform(scale(1));
      opacity: 1;
    }
  }
}

.tb {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  .tb-cell {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
  }
}

.off-desc-vm {
  .vm-product-descr-container-1 {
    display: none;
  }
}

.off-vote-vm {
  .vm-product-rating-container {
    display: none;
  }
}

.off-btn-detail {
  .vm-details-button {
    display: none;
  }
}

.off-pagin-top {
  .orderby-displaynumber {
    display: none;
  }
}

.vm-pagination {
  display: block;
  float: none;
  position: fixed;
  right: auto;
  text-align: left !important;
  top: 195px;
  width: auto;
  z-index: 99;
  .vm-page-counter {
    position: absolute;
    right: 0;
    top: 30%;
  }
  ul {
    text-align: left !important;
    li {
      padding: 0;
      display: block;
      background: @cus_color5;
      margin: 2px 0;
      border-left: 1px solid @cus_color5;
      .transition(all 0.3s linear 0s);
      &:first-child {
        margin-top: 0;
      }
      &.actived, &:hover {
        border-right: 1px solid @cus_color1;
        background: @white;
      }
      &.navi {
        display: none;
      }
      a {
        padding: 12px;
        background: transparent;
        border: none;
        display: block;
        margin: 0;
        color: @black;
        text-align: center;
      }
    }
  }
}

.cart-view {
  padding: 60px;
  position: relative;
}

.vm-cart-header-container {
  position: relative;
  border-bottom: 1px solid @cus_color2;
  padding-bottom: 29px;

  .title-cart {
    margin: -3px 0 0 0;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
  }
}

#form-login {
  padding-top: 30px;
  margin-top: 10px;
  margin-bottom: 50px;
}

#com-form-login {
  padding-top: 30px;
  margin-top: 10px;
  margin-bottom: 50px;
  .userdata {
    > p {
      padding-bottom: 30px;
    }
    .inputbox {
      background: none repeat scroll 0 0 transparent;
      border: 1px solid @cus_color2;
      box-shadow: none;
      margin: 0;
      padding: 12px 10px;
      width: 18%;
    }
    .com-form-login-username,
    .com-form-login-password,
    .com-form-login-remember {
      margin-bottom: 14px;
    }
    .com-form-login-remember {
      .default {
        background: @black;
        box-shadow: none;
        text-transform: uppercase;
        border: none;
        color: @white;
        padding: 9px 20px;
        font-size: 12px;
        line-height: 1;
        margin-bottom: 20px;
      }
      #remember {
        width: auto;
      }
      label {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .forgot-u, .forgot-p {
    font-size: 12px;
    color: @black;
    &:hover {
      color: @black;
    }
  }
}

#checkoutForm {
  border-top: 1px solid @cus_color2;
  .billto-shipto {
    border-bottom: 1px solid @cus_color2;
    border-top: medium none;
    margin: 0;
    padding: 45px 0;
  }
  .vm-fieldset-pricelist {
    padding-top: 45px;
  }
  table.cart-summary {
    border: 1px solid @cus_color2;
    .product-name {
      width: 50%;
      a {
        color: @black;
      }
    }
    tr {
      th {
        background: @black;
        color: @white;
        padding: 12px 15px;
        font-size: 14px;
        font-weight: normal;
        border-bottom: none;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
      td {
        min-width: 100px;
        padding: 30px 15px;
        position: relative;
        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
        &.vm-cart-images {
          width: 10%;
        }
        .cart-images {
          img {
            float: none;
            width: 100%;
            margin: 0;
          }
        }
      }
      &.cart-items {
        border-bottom: 1px solid @cus_color2;
      }
      .cart-item-border {
        border-left: 1px solid @cus_color2;;
      }
    }
  }
}

.vmicon.vm2-remove_from_cart {
  background: none;
  border: none;
  box-shadow: none;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}

.cart-items {
  .vm-price-value {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    > span {
      font-size: 14px;
      color: @black;
      font-weight: 300;
    }
  }
}

.vm-cart-total-prices {
  .vm-price-value > span {
    color: @cus_color1;
    font-weight: 600;
  }
}

.vm-quantity {
  input.quantity-input {
    border-radius: 0;
    background: @white;
    padding: 10px;
    color: #565555;
    height: 32px !important;
    min-width: 70px;
  }
  .vm2-add_quantity_cart {
    background: none;
    font-size: 18px;
    position: relative;
    top: 3px;
  }
}

.vm-cart-input-quantity {
  position: absolute;
  top: 50%;
  margin-top: -25px;
}

.upper {
  text-transform: uppercase;
}

.gobal_title {
  border-bottom: 1px solid @black;
  padding-bottom: 15px;
  margin: 0 0 28px 0;
}

.vm-cart-custom {
  padding-top: 45px;
  &.row {
    margin: 0 -15px !important;
  }
  .coupons,
  .shipping-payment,
  .total-prices {
    width: 33.33333%;
    float: left;
    padding: 0 15px;
  }
  .total-prices {

    .PricebillTotal {
      display: inline-block;
      float: right;
      font-size: 14px;

    }
  }
}

.billTotal {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.vm-coupon-title {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
}

.buttonBar-right {
  .vm-button-correct {
    background: @white;
    border-radius: 0;
    border: 1px solid @black;
    color: @black;
  }
}

.coupons {
  .coupon {
    background: @white;
    border-radius: 0;
    width: 100%;
    font-size: 12px;
    padding: 6px;
  }
  .details-button {
    margin: 15px 0 0 0;
    display: block;
    .details-button {
      background: none repeat scroll 0 0 @black;
      border-radius: 0;
      color: @white;
      font-size: 12px;
      text-transform: uppercase;
      padding: 5px 15px;
      margin: 0;
    }
  }
}

.checkout-button-top {
  border: none;
  padding: 0;
  text-align: center;
  .vm-button-correct, .vm-button {
    background: @cus_color1;
    color: @white;
    border: 1px solid @cus_color1;
  }
}

.checkout-button-top .vm-button-correct,
.checkout-button-top .vm-button,
.vm-continue-shopping a {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 0;
  width: 100%;
  display: block;
  border-radius: 0;
  margin: 15px 0;
}

.vm-continue-shopping {
  a {
    border: 1px solid @black;
    background: @white;
    color: @black;
  }
}

textarea#customer_note_field {
  border: 1px solid @cus_color2;
  border-radius: 0;
  height: 150px;
  margin: 10px 0;
  width: 100%;
}

/*----- Search Prices -----*/
.virtuemart_search {
  > h4, .ui-slider {
    margin-bottom: 30px;
    line-height: 1;

  }
  > h4 {
    margin: 0;
    line-height: 1;
  }
  .ui-widget > div {
    height: 5px !important;
    overflow: visible !important;
  }
  .ui-widget-content {
    background: rgba(0, 0, 0, 0.3);
    height: 5px;
    border-radius: 0;
    border: none;
    margin: 0 !important;
    .ui-slider-range {
      background: rgba(0, 0, 0, 0.3);
    }
    .ui-slider-handle {
      top: -5px;
      margin: 0;
      border-radius: 0;
      background: @cus_color1;
      border: none;
      height: 15px;
      width: 15px;
    }
    .ui-state-hover {
      cursor: pointer;
    }

  }
  .info-filter-price {
    margin-bottom: 25px;
    margin-top: 15px;
  }

  .button {
    background: @black;
    color: @white;
    border-radius: 0;
    padding: 5px 15px;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
  }
  .ui-slider {
    overflow: visible !important;
  }
  .input {
    border: none;
    background: none;
    height: auto;
    width: auto;
  }

}

/*------ Random Category VM ----*/
.vm-categories-random {
  position: relative;
  > img {
    width: 100%;
  }
  .random-info {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  i {
    font-size: 48px;
    margin-bottom: 10%;
  }
  h5, a, i, h4 {
    display: block;
  }
  .total {
    margin: 20px 0 30px;
    color: @black;
  }
  .category-title {
    font-size: 30px;
    color: @black;
  }
  .random-category-readmore {
    background: @black;
    display: inline-block;
    font-size: 12px;
    padding: 5px 15px;
    color: @white;
    margin-top: 5px;
  }
}

.ds-table {
  display: table;
  width: 100%;
  height: 100%;
  .ds-table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}

.random-background {
  background: rgba(255, 255, 255, 0.7);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.3s linear 0.1s;
  width: 100%;
}

/*----- Twitter ----*/
.tztwd-tweet {
  line-height: 2em;
  margin-bottom: 5px;
}

.tztwd-container {
  font-size: 14px;
}

.tztwd-tweet-data {
  text-align: left !important;
  i {
    color: #d4d4d4;
  }
}

.tztwd-tweet-container {
  padding: 10px 0 !important;
  &:first-child {
    padding-top: 0 !important;
  }
  &:last-child {
    padding-bottom: 0 !important;
  }
}

/*----- Feature Article ----*/
.TzModFeature {
  li {
    border: none !important;
    &:first-child {
      padding-top: 0 !important;
    }
    &:last-child {
      padding-bottom: 0 !important;
    }
    a.FeatureTitle {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 15px;
      display: block;
    }
    .introtext {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

/*----- Last New ----*/
.latestnews {
  li {
    padding: 15px 0;
    a {
      color: @black;
      font-size: 14px;
      &.title {
        &:hover {
          color: @cus_color1;
        }
      }
    }
    .created {
      font-size: 12px;
      display: block;
    }
    &.first {
      padding-top: 0;
    }
    &.last {
      padding-bottom: 0;
    }
    img {
      width: 100%;
      max-height: 60px;
    }
    > a, > span {
      float: left;
    }
    .tz-media {
      margin-right: 30px;
      width: 35%;
    }
  }
}

/*----- Tag ----*/
ul.mod_tz_tag {
  li {
    a {
      color: #34373b;
      &:hover {
        color: @cus_color1;
      }
    }
  }
}

/*---- Vote ----*/
.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  text-align: left;
}

.rating > span {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 17px;
}

.rating > .rating-item:hover span:before,
.rating > .rating-item:hover ~ span span:before,
.rating > .voted:before,
.rating > .rating-item:hover:before,
.rating > .rating-item:hover ~ .rating-item:before {
  color: @cus_color1;
}

.rating > .voted span {
  background: none repeat scroll 0 0 white;
  display: inline-block;
  height: 105%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}

/*----- Blobg Single ----*/
.TzArticleMedia {
  margin-bottom: 0;
}

.padding-0-75 {
  > .container {
    padding-left: 75px;
    padding-right: 75px;
    background: @white;
  }
}

h3.count_cm {
  display: none;
}

.tz-blog {
  .blog_type_article {
    i {
      margin-right: 10px;
    }
  }
  &.info {
    a, a span, .TzCreate, .TZCommentCount, .TzHits, .blog_type_article {
      color: rgba(0, 0, 0, 0.5);
      font-weight: normal !important;
    }
    .TzCreatedby, .TzArticleCategory, .TzCreate, .TZCommentCount {
      > span {
        color: rgba(173, 174, 175, 0.5);
      }
    }
    .blog_type_article > i {
      color: rgba(173, 174, 175, 0.5);
    }
  }
  .tz_portfolio_image > img {
    width: 100%;
  }
  &.tz-blog-full-text p {
    padding: 20px 0;
    line-height: 2;
  }
  .button_scroll {
    display: none;
  }
  .TzArticleTitle {
    font-size: 30px;
    text-align: left;
    padding-top: 60px;
    padding-bottom: 20px;
    text-transform: none;

  }
  .TzArticleMedia {
    padding: 10px 0 0;
    margin-bottom: 0;
  }
  .share-post {
    border-bottom: 1px dashed @cus_color3;
    line-height: 1;
    margin-top: 15px;
    padding-bottom: 60px;

    a {
      color: @black;
      &:hover {
        color: @black;
      }
      i {
        font-size: 28px;
      }
    }
  }
  .tz_portfolio_user {
    margin: 60px 0;
    .AuthorSocial {
      bottom: 0;
      position: absolute;
      text-align: center;
      width: 100%;
      background: rgba(0, 0, 0, 0.57);
      padding: 5px 0;
      a:hover {
        i {
          color: @cus_color1;
        }
      }
      i {
        color: @white;
      }
    }
    .AuthorAvatar {
      position: relative;
      padding: 0 !important;
      margin-right: 30px;
      min-height: 150px;
      min-width: 150px;
      img {
        width: 100%;
        max-width: none;
      }
    }
    .media-heading {
      margin: 30px 0 20px;
      a {
        position: relative;
        color: @black;
        font-weight: bold;
      }
    }
  }
  .tz_portfolio_comment {
    border-top: 1px dashed @cus_color3;
    padding-top: 50px;
    h4.count_cm {
      display: none;
    }
    h3.leave_comment,
    h3.count_cm {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  div#comments div.comment-avatar {
    //    border: 1px solid rgba(0, 0, 0, 0.1);
    //    padding: 5px;
  }
  div#comments div#comments-list-0 .comment_item {
    border-bottom-style: dashed;
  }
  div#comments .comments_form {
    padding-bottom: 60px;
  }
  &.navi_article .pagenav {
    border-top-style: dashed;
    border-bottom: none;
  }
  #comments-form {
    .col-md-6 {
      width: 100%;
    }
  }
}

#tz-portfolio-template-info-category {
  > .container {
    padding-top: 55px;
  }

  .TzCategoryTitle {
    font-size: 30px;
    margin-bottom: 20px;
    display: block;
    font-weight: bold;
  }
  .hr {
    border-top: 1px dashed rgba(52, 55, 59, 0.1);
    margin: 60px 0 0;
  }
}

/*---- Pages ----*/
#searchForm, .form-validate.form-horizontal {
  margin: auto;
  width: 60%;
}

#member-registration .control-label {
  text-align: left;
}

/*----- Page Login -----*/
.page-login {
  .login-desc {
    i {
      font-size: 72px;
      display: block;
      margin-bottom: 25px;
    }
    p {
      font-size: 48px;
      &.p-small {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.7);
      }
      &.strong {
        font-weight: 600;
      }
    }
    i, p {
      color: @black;
    }
  }
  .form-validate {
    padding: 45px 0 35px;
    .control-group {
      .controls {
        input {
          border: 1px solid @black;
          box-shadow: none;
          line-height: 1.5;
          margin: 10px 0;
          padding: 10px 20px;
          width: 30%;
        }
        input#remember {
          width: auto;
          position: relative;
          left: -7px;
        }
        .ck_remember {
          position: relative;
          left: -7px;
          font-size: 13px;
          font-weight: lighter;
        }
      }
    }
    .btn-primary {
      width: 30%;
      background: @black;
      text-transform: uppercase;
      border-radius: 0;
      box-shadow: none;
      border: none;
      line-height: 1.5;
      padding: 11px;
      margin-top: 10px;
    }
  }
}

.menu.menu-login {
  display: block !important;
  height: auto !important;
  li {
    display: inline-block;
    a {
      color: #838383;
      text-transform: uppercase;
      font-size: 12px;
      padding: 15px 25px;
    }

    &.active, &:hover {
      a {
        background: transparent;
        color: @black;
      }
    }
    &.open {
      a {
        background: transparent;
      }
    }
  }
}

.nav-stacked {
  border: none;
  display: inline-block;
  margin: 0;
  position: relative;
  top: 5px;
  li {
    float: left;
    margin: 0;
    + li {
      margin: 0;
    }
    a {
      font-family: Lato;
      font-size: 13px;
      font-weight: lighter;
      color: @black;
      margin: 0;
      padding: 0 20px 0 0;
      border: none;
      &:hover {
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
    &.open {
      &:hover {
        margin: 0;
      }
      a {
        border: none;
        background: transparent;
        &:hover {
          background: transparent;
        }
      }
    }
    &:first-child {
      a {
        padding-left: 0;
      }
    }
    &:last-child {
      a {
        padding-right: 0;
      }
    }
  }
}

.footer-login {
  font-size: 13px;
  padding-top: 20px;
  color: @black;
  a {
    color: @black;
  }
}

/*-----To Top ----*/
.tz-totop {
  background: @black;
  bottom: 30px;
  height: 45px;
  position: fixed;
  right: 30px;
  width: 45px;
  opacity: 0;
  cursor: pointer;
  .transition(all 0.3s ease 0s);
  i {
    color: @white;
    display: block;
    font-size: 18px;
    margin: auto;
    padding: 14px;
  }
}

/*------ Team ----*/

.title_mod {
  padding-top: 60px;
  margin-right: 15px;
  h4 {
    margin: 0;
    font-weight: bold;
  }
}

.team {
  > div {
    padding-top: 30px;
    overflow: hidden;
    padding-bottom: 5px;
  }
  .team-box {
    background: @white;
    border-style: none solid solid solid;
    border-width: 0 1px 1px 1px;
    border-color: rgba(0, 0, 0, 0.1);
    &:hover {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
    }
  }
  .team-img {
    overflow: hidden;
  }
  .team-details {
    h5 {
      margin: 5px 0 0;
    }
    hr.hr {
      margin: 15px 0 !important;
    }
  }
  img {
    position: relative;
  }

  .gry-bg, .t-position {
    text-transform: uppercase;
  }
  .gry-bg {
    margin: 0;
  }

  .team-details {
    text-align: center;
    padding: 15px;
    .t-desc {
      font-size: 13px;
      padding: 0 15px;
      color: #838383;
      margin: 15px 0;
    }
    .t-position {
      font-size: 10px;
      color: #838383;
    }
  }
  .team-socials {
    ul {
      li {
        display: inline-block;
        a {
          display: block;
          margin: 0;
          height: 35px;
          width: 35px;
          background: #d9d9d9;
          padding: 10px;
          i {
            color: @white;
          }
          &:hover {
            background: @black;
            i {
              color: @white;
            }
          }
        }
      }
    }
  }
}

/*---- Serivce ---*/
.row {
  &.service {
    margin-left: -20px;
    margin-right: -20px;
  }
  .service-box-1 {
    margin: 25px 0 20px;
    padding-left: 20px;
    padding-right: 20px;
    &:hover {
      .box-top .box-info-1 .box-icon {
        background: @cus_color1;
      }
      .box-top .box-info-1 i {
        .transform(rotate(360deg));
      }
    }
    .box-top {
      .box-info-1 {
        padding-bottom: 20px;
        i {
          font-size: 24px;
          display: inline-block;
          height: 45px;
          width: 45px;
          padding: 10px;
          color: @white;
          .transition(all 0.4s ease 0.25s);
        }
        .box-icon {
          background: @black;
          padding-left: 0;
          height: 45px;
          width: 45px;
          display: inline-block;
          .transition(all 0.4s ease 0.25s);
        }
        span {
          font-size: 16px;
          padding-left: 15px;
          position: relative;
          top: -3px;
          a {
            color: @black;
          }
        }
      }
      .box-info-2 {
        font-weight: 300;
        color: #838383;
        font-size: 14px;
      }
    }
  }
  .service-box-2 {
    padding-bottom: 15px;
    padding-top: 15px;

    .box-top {
      background: @white;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
      }
      .box-info-1 {
        overflow: hidden;
        padding: 0;
        img {
          position: relative;
        }
      }
      .box-info-2 {
        padding: 20px 15px 20px 30px;
        .title {
          font-size: 16px;
          padding: 15px 0;
        }
        .desc {
          font-size: 12px;
          font-weight: 300;
          padding-bottom: 30px;
          color: #838383;
        }
        .more-btn {
          color: @white;
          background: @black;
          padding: 5px 10px;
          font-size: 10px;
          position: relative;
          display: inline-block;
        }
      }
    }
  }
}

.weblinks {
  padding-top: 15px;
  .slick-slide {
    &:hover {
      a {
        border-color: @cus_color1;
      }
    }
    a {
      margin: 0 10px;
      display: block;
      border: 1px solid #ebebeb;
      background: @white;
      .transition(all 0.2s linear 0s);
    }

  }
  .slick-prev, .slick-next {
    background: #d9d9d9;
    height: 25px;
    width: 30px;
    border-radius: 0;
    .transition(all 0.2s linear 0s);
    &:before {
      color: @white;
    }
    &:hover {
      background: @black;
    }
  }
  .slick-prev {
    right: 35px;
    top: -75px;
  }
  .slick-next {
    right: 0;
    top: -75px;
  }
  .slick-prev:before {
    content: '\e765';
  }
  .slick-next:before {
    content: '\e766';
  }
  .white-bg img {
    width: 100%;
  }
}

/*----- Page Tag ----*/
.TzTag {
  .TzTagInner {
    margin: auto;
    width: 60%;
    img {
      width: 100%;
    }
    .TzTagTitle {
      margin: 0;
      a {
        color: @black;
        font-weight: bold;
      }
    }
    .TzLeading {
      padding: 60px 0;
      margin-bottom: 0;
    }
    .blog_type_article {
      i {
        margin-right: 5px;
      }
    }
    .TzDescription {
      padding: 20px 0 0;
      p {
        line-height: 2em;
      }
    }
    .TzTagArticleInfo {
      padding: 25px 0 0;
      > span {
        color: #adaeaf;
        > span {
          color: @black_op05;
          > span {
            color: @black_op05;
          }
        }
        a {
          color: @black_op05;
        }
      }
    }
    .TzTagMedia {
      padding: 20px 0 0;
    }
    .TzReadmore {
      color: @cus_color1;
      padding: 13px 0 0;
      &:hover {
        color: @black;
        i {
          color: @black;
        }
      }
    }
  }
}

/*---- 404 ----*/
.pos_ab {
  position: relative;
  width: 100%;
  height: 100%;
}

.error_code {
  font-size: 159px;
  color: @black;
  font-weight: 700;
}

.font-36 {
  font-size: 36px;
  color: rgba(0, 0, 0, 0.3);
}

.font-19 {
  font-size: 19px;
  color: @black;
}

.back-home {
  background: @black;
  color: @white;
  padding: 5px 15px;
  display: inline-block;
  margin-top: 50px;
  &:hover {
    color: @white;
  }
}

.report_error {
  font-size: 46px;
  color: rgba(0, 0, 0, 0.7);
}

.page-404 {
  #tz-portfolio-template-info-category {
    display: none;
  }
}

.view-login,
.page-404 {
  #component-area {
    margin: 0;
  }
}

/*----- Button Effect -----*/
.oe-btn {
  background-color: transparent;
  outline: 0 none;
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-out 0s;
}

.oe-btn span {
  display: inline-block;
  position: relative;
  .transition(all 0.4s ease-out 0s);
  z-index: 10;
}

.oe-btn:hover::before {
  background-color: @cus_color1;
  left: 0;
  opacity: 1;
}

.oe-btn::before, .oe-btn .fa::after {
  background-color: #444444;
  color: @white;
  content: "";
  display: block;
  height: 100%;
  left: -100%;
  line-height: 1.5em;
  opacity: 0.5;
  padding: 1em 1.3em;
  position: absolute;
  top: 0;
  transition: all 0.4s ease-out 0s;
  width: 100%;
  z-index: 1;
}

/*----- Contact -----*/
.contact {
  margin: 0 -15px;
  .ds-table {
    z-index: 9;
    position: relative;
  }
}

.image_contact {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  &:before {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
  }
  img {
    position: relative;
  }
  video {
    width: 100%;
    position: relative;
  }
}

.pos_left, .pos_right {
  float: left;
  width: 50%;
  > * {
    width: 75%;
  }
}

.pos_left > * {
  float: right;
  padding-right: 15px;
}

.pos_right > * {
  float: left;
  padding-left: 15px;
}

.view-contact {
  #component-area {
    margin: 0;
  }
}

.contact-miscinfo {
  h3 {
    font-size: 42px;
    font-weight: bold;
    margin: 0;
    line-height: 1.5em;
    padding-bottom: 10px;
  }
  h3, .contact-misc {
    text-align: left;
    color: @black;
    float: left;
  }
  .contact-misc {
    line-height: 2em;
  }
}

.contact-address {
  padding-top: 30px;
  > div {
    float: left;
    width: 50%;
    min-height: 80px;
    margin-bottom: 20px;
    > span {
      color: @black_op05;
      float: left;
      a {
        color: @black_op05;
      }
    }
  }
  .title_info {
    font-size: 18px;
    text-align: left;
    color: @black;
  }
}

.contact-links {
  ul {
    float: left;
    i {
      font-size: 18px;
      color: @black;
    }
    li:hover {
      i {
        color: @cus_color1;
      }
    }
  }
}

.liona-form {
  .control-group {
    padding: 5px 0;
  }
  .controls {
    text-align: left;
    > input, > textarea {
      width: 93%;
      padding: 8px 15px;
      border: 1px solid #ebebeb;
      .transition(all 0.3s ease 0s);
      &.invalid, &:focus {
        border: 1px solid #c16e5f;
        background: @white;
        .box-shadow(0 0 9px 5px rgba(212, 44, 14, 0.09));
        color: #c26f60;
      }
    }
  }
  .form-actions {
    padding: 25px 0;
    text-align: left;
    button {
      border-radius: 0;
      background: @black;
      color: @white;
      border-color: @black;
      padding: 10px 30px;
      .transition(all 0.3s ease 0s);
      &:hover {
        background: @cus_color1;
        border-color: @cus_color1;
      }
    }
  }
  .title_for_form {
    font-size: 42px;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
    color: @black;
  }
}

/*----- Color -----*/

.menu-item,
div.mod-languages ul li a {
  color: @black;
}

.btn_menu,
.btn_filter,
.btn_search,
.text-logo {
  color: @black;
  &:hover {
    color: @black;
  }
}

#plazart-mainnav.navbar-default {
  .navbar-nav {
    > li {
      > a {
        color: #b3b3b3;
        &:hover {
          background: transparent;
          color: @white;
        }
      }
      &.active {
        > a, > a:hover, > a:focus {
          color: @white;
          background: transparent;
        }
      }

    }
    > .open {
      > a, > a:hover, > a:focus {
        background: transparent;
        color: @white;
      }
    }
    .mega-group {
      > a {
        color: @white !important;
        background: transparent !important;
        &:hover {
          background: transparent;
          color: @white;
        }
      }
      > .mega-group-ct {
        li {
          a {
            color: #cccccc;
            i {
              color: #565555;
              line-height: 0.1;
              margin-right: 10px;
            }
          }
          &:hover {
            a {
              background: @white;
              color: @black;
            }
            i {
              color: @black;
            }
          }
        }
      }
    }
  }
  .dropdown-menu {
    ul {
      li {
        &.active {
          > a {
            background: @white;
            color: @black;
          }
        }
        a {
          color: @white;
          &:hover {
            background: @white;
            color: @black;
          }
        }
      }
    }
  }
}

.plazart-megamenu ul li {
  display: block !important;
}

/*----- Hoshi -----*/
.input--hoshi {
  overflow: hidden;
}

.input__field--hoshi {
  margin-top: 1em;
  padding: 0.85em 0.15em;
  width: 100%;
}

.input__label--hoshi {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: 100%;
  text-align: left;
  pointer-events: none;
}

.input__label-content--hoshi {
  position: absolute;
}

.input__label--hoshi::before,
.input__label--hoshi::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #B9C1CA;
}

.input__label--hoshi::after {
  margin-top: 2px;
  border-bottom: 4px solid red;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.input__label--hoshi-color-1::after {
  border-color: @cus_color1;
}

.input__label--hoshi-color-2::after {
  border-color: hsl(160, 100%, 50%);
}

.input__label--hoshi-color-3::after {
  border-color: hsl(20, 100%, 50%);
}

.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
  -webkit-animation: anim-1 0.3s forwards;
  animation: anim-1 0.3s forwards;
}

@-webkit-keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

@keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  height: 110px;
}

.input__field {
  position: absolute;
  display: block;
  bottom: 0;
  padding: 0.5em 0;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 20px;
  margin: 0;
  background: transparent;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0;
  width: 100%;
  font-weight: 300;
  font-size: 60px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: absolute;
  display: block;
  padding: 0;
  width: 100%;
  bottom: 0;
}

/*------ About -----*/
.about_title {
  font-weight: 600;
  margin: 30px 0;
}

.padding-0-80 {
  padding: 0 80px;
}

.text-custom .module-title {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  font-size: 20px;
  font-style: italic;
  margin: 5px 0 15px;
  padding: 20px 0;
}

/*---- Loading ----*/
.loading {
  height: 100%;
  width: 100%;
  background: transparent;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  > div {
    background: @white;
  }
}

.horizontal {
  .loading {
    padding: 70px 30px 30px 30px;
  }
}

.vertical {
  .loading {
    padding: 30px 30px 30px 75px;
  }
}

/*---- Home Carousel----*/
.home-carousel {
  .owl-stage-outer, .owl-stage, .owl-item, .item {
    height: 100%;
    cursor:zoom-in;
  }
  .owl-item img {
    height: 100%;
    width: auto !important;
  }
  .item {
    padding-left: 15px;
  }

  .owl-next i, .owl-prev i {
    font-size: 48px;
  }

  .owl-controls {
    margin-top: -50px;
    position: absolute;
    top: 50%;
    width: 100%;
    .owl-next, .owl-prev {
      &:hover {
        i {
          color: rgba(255,255,255,0.5);
        }
      }
      i {
        color: @white;
      }
    }
    .owl-next {
      position: absolute;
      right: 25px;
    }
    .owl-prev {
      position: absolute;
      left: 25px;
    }
  }
}



.carousel_info {
  padding-top: 25px;
  .title_article {
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
    top: 2px;
    min-width: 100px;
  }
  .slide_data {
    float: right;
  }
  .share-post {
    padding-left: 20px;
    position: relative;
    top: 2px;
    i {
      color: @black;
      padding-right: 2px;
    }
  }

  .btn-prev, .btn-next {
    i {
      color: @black;
    }
  }
}

.carousel_info > * {
  display: inline-block;
}

.vertical {
  .home-carousel {
    .owl-stage {
      margin-left: -15px;
    }
  }
}

.horizontal {
  .home-carousel {
    .owl-stage {
      margin-left: -15px;
    }
  }
}

